import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  Fragment,
  forwardRef,
  useMemo,
  memo,
} from 'react';
import {
  ClickAwayListener,
  ButtonBase,
  Box,
  colors,
  makeStyles,
  useTheme,
  useMediaQuery,
  Popper,
  Paper,
  alpha,
  InputAdornment,
  Collapse,
  LinearProgress,
} from '@material-ui/core';
import { PulseLoader, SyncLoader } from 'react-spinners';
import useResizeObserver from '@react-hook/resize-observer';

import Autocomplete from './index';
import Scrollbars from '../ScrollBars';
import { ShowCustomizedEndAdornment } from '../Input';

const AsyncPopperAutoComplete = forwardRef(
  (
    {
      startAdornment = ({ setDisableParentRipple, openMenu }) => null,
      endAdornment = ({
        errorAndSuccessComponents,
        setDisableParentRipple,
        openMenu,
      }) => (
        <InputAdornment position="end">
          {errorAndSuccessComponents}
        </InputAdornment>
      ),
      onTypeLoad = null,
      onPopperClose = null,
      darkMode = false,
      loading = false,
      loadingComponent = null,
      autoCompleteLoadingComponent = null,
      contentComponent = null,
      placeholderComponent = null,
      suggestionText = null,
      noAutoCompleteText = null,
      onClick = null,
      onChange = null,
      onBlur = null,
      helperText = '',
      helperTextClassName = '',
      errorHelperTextClassName = '',
      showValidationDefault = true,
      error = false,
      touched = true,
      buttonWidthTruncate = 150,
      buttonProps = {},
      options = null,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles();
    const theme = useTheme();

    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const containerRef = useRef(null);
    const buttonRef = useRef(null);
    const counterRef = useRef(0);

    const [disableParentRipple, setDisableParentRipple] = useState(false);
    const [popperOpen, setPopperOpen] = useState(false);
    const [containerSize, setContainerSize] = useState(0);
    const [value, setValue] = useState(null);
    const [typedValue, setTypedValue] = useState('');
    const [localOptions, setLocalOptions] = useState(null);
    const [fetchingOptions, setFetchingOptions] = useState(false);
    const [showLinearProgress, setShowLinearProgress] = useState(false);

    const { getOptionLabel } = props;

    useResizeObserver(containerRef, (entry) =>
      setContainerSize(entry.contentRect),
    );

    const optionsArr = useMemo(
      () =>
        (localOptions || options || []).map((item) => ({
          ...item,
          ___label: typedValue,
        })),
      [localOptions, options, typedValue],
    );

    const openMenu = (event) => {
      event.stopPropagation();
      setPopperOpen(true);
    };

    useEffect(() => {
      if (!popperOpen) {
        setValue(null);
      }
    }, [popperOpen, getOptionLabel, value]);

    useEffect(() => {
      if (
        !popperOpen &&
        counterRef.current > 0 &&
        typeof onPopperClose === 'function'
      ) {
        onPopperClose();
      } else if (popperOpen && counterRef.current <= 0) {
        counterRef.current++;
      }
    }, [onPopperClose, popperOpen]);

    useEffect(() => {
      if (value !== null) {
        setPopperOpen(false);
        setTypedValue('');
      }
    }, [value]);

    useEffect(() => {
      setShowLinearProgress(true);
      const timer = setTimeout(() => {
        if (onTypeLoad) {
          setFetchingOptions(true);
          onTypeLoad({ pattern: typedValue }).then((dataList) => {
            setFetchingOptions(false);
            setLocalOptions(dataList);
            setShowLinearProgress(false);
          });
        }
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }, [typedValue, onTypeLoad]);

    useImperativeHandle(ref, () => ({
      value: contentComponent,
      focus: buttonRef.current ? () => buttonRef.current.focus() : () => {},
      click: buttonRef.current ? () => buttonRef.current.click() : () => {},
    }));

    const helperTextClasses = [classes.HelperTextDiv, helperTextClassName];

    if (showValidationDefault) {
      if (error) {
        helperTextClasses.push(classes.ErrorDiv);
        helperTextClasses.push(errorHelperTextClassName);
      }
    }

    return (
      <div
        className={classes.TextFieldContainer}
        style={{
          '--color': darkMode ? '#fff' : theme.palette.primary.main,
          '--box-shadowColor': darkMode
            ? alpha('#fefefe', 0.1)
            : error && touched
            ? alpha(theme.palette.error.main, 0.3)
            : touched
            ? alpha(theme.palette.success.main, 0.3)
            : alpha(theme.palette.secondary.main, 0.3),
          '--border-color': darkMode
            ? '#fff'
            : error && touched
            ? theme.palette.error.main
            : touched
            ? theme.palette.success.main
            : theme.palette.secondary.main,
          '--backgroundColorOnFocus': darkMode ? '#7079c4' : '#fefefe',
        }}
      >
        <ClickAwayListener onClickAway={setPopperOpen.bind(null, false)}>
          <div
            style={{
              height: '100%',
              width: '100%',
              '--autoComplete-width': containerSize.width || 0,
            }}
            ref={containerRef}
            className={classes.Container}
          >
            <ButtonBase
              ref={buttonRef}
              disableRipple={disableParentRipple}
              classes={{
                root: [
                  classes.InputContainerReplacement,
                  popperOpen ? classes.ButtonFocused : '',
                ].join(' '),
              }}
              onKeyDown={(e) => {
                if (e.code === 'Tab' && typeof onBlur === 'function') {
                  onBlur(e);
                }
              }}
              style={{
                backgroundColor: darkMode
                  ? '#7079c4'
                  : error && touched
                  ? alpha(theme.palette.error.main, 0.2)
                  : touched
                  ? alpha(theme.palette.success.main, 0.2)
                  : '#f0f0f0',
                paddingLeft: startAdornment({
                  setDisableParentRipple,
                  openMenu,
                })
                  ? theme.typography.pxToRem(14)
                  : theme.typography.pxToRem(20),
              }}
              onClick={onClick ? onClick : openMenu}
              role="input"
              {...buttonProps}
            >
              {startAdornment({ setDisableParentRipple, openMenu })}
              <Box className={classes.InputBox}>
                {loading ? (
                  React.isValidElement(loadingComponent) ? (
                    loadingComponent
                  ) : (
                    <Box style={{ textAlign: 'center' }}>
                      <PulseLoader
                        color={darkMode ? '#fff' : theme.palette.secondary.main}
                        size={10}
                      />
                    </Box>
                  )
                ) : !contentComponent ? (
                  React.isValidElement(placeholderComponent) ? (
                    placeholderComponent
                  ) : (
                    <Box
                      style={{
                        color: darkMode
                          ? alpha('#fff', 0.4)
                          : alpha('#000', 0.4),
                      }}
                    >
                      {placeholderComponent}
                    </Box>
                  )
                ) : React.isValidElement(contentComponent) ? (
                  contentComponent
                ) : (
                  <Box
                    style={{
                      width: (containerSize.width || 0) - buttonWidthTruncate,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: darkMode ? '#fff' : '#000',
                    }}
                  >
                    {contentComponent}
                  </Box>
                )}
              </Box>
              <ShowCustomizedEndAdornment
                error={error}
                touched={touched}
                render={({ errorAndSuccessComponents }) =>
                  endAdornment({
                    errorAndSuccessComponents,
                    setDisableParentRipple,
                    openMenu,
                  })
                }
              />
            </ButtonBase>
            <Popper
              open={popperOpen}
              anchorEl={containerRef.current}
              placement="bottom-start"
              className={classes.Popper}
              style={{
                width: containerSize.width || 0,
                zIndex: 999,
                paddingBottom: 3,
              }}
            >
              {noAutoCompleteText ? (
                <div className={classes.NoAutoCompleteTextHeader}>
                  {noAutoCompleteText}
                </div>
              ) : (
                <Fragment>
                  {showLinearProgress ? (
                    <LinearProgress color="secondary" />
                  ) : (
                    <div style={{ height: 4 }} />
                  )}
                  {suggestionText && (
                    <div className={classes.AutoCompleteHeader}>
                      {suggestionText}
                    </div>
                  )}
                  <div
                    style={{
                      padding: theme.spacing(
                        theme.typography.pxToRem(4),
                        theme.typography.pxToRem(4),
                        0,
                        theme.typography.pxToRem(4),
                      ),
                    }}
                  >
                    <Autocomplete
                      open
                      onClose={setPopperOpen.bind(null, false)}
                      classes={{
                        paper: classes.paper,
                        option: classes.option,
                        popperDisablePortal: classes.popperDisablePortal,
                      }}
                      PaperComponent={React.forwardRef((paperProps, ref) => (
                        <Paper
                          classes={{ root: classes.AutoCompletePaper }}
                          {...paperProps}
                          ref={ref}
                          style={{
                            width: containerSize.width || 0,
                            marginLeft: `-${theme.typography.pxToRem(5)}`,
                            height:
                              (matchesUpSm &&
                                (localOptions || options || []).length > 4) ||
                              (!matchesUpSm &&
                                (localOptions || options || []).length > 3)
                                ? theme.typography.pxToRem(160)
                                : 'auto',
                          }}
                        >
                          {(matchesUpSm &&
                            (localOptions || options || []).length > 4) ||
                          (!matchesUpSm &&
                            (localOptions || options || []).length > 3) ? (
                            <Scrollbars>{paperProps.children}</Scrollbars>
                          ) : (
                            paperProps.children
                          )}
                        </Paper>
                      ))}
                      value={value}
                      onChange={(event, newValue) => {
                        if (onChange) {
                          onChange(getOptionLabel(newValue), newValue);
                        }
                        setValue(newValue);
                      }}
                      inputValue={typedValue}
                      onInputChange={(_, value, reason) => setTypedValue(value)}
                      textFieldProps={{
                        autoFocus: true,
                        onMouseDown: setPopperOpen.bind(null, true),
                      }}
                      disableCloseOnSelect
                      disablePortal
                      {...props}
                      options={optionsArr}
                      getOptionLabel={(option) => option['___label']}
                      loading={fetchingOptions}
                      loadingText={
                        autoCompleteLoadingComponent || (
                          <div
                            style={{
                              padding: theme.typography.pxToRem(10),
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <SyncLoader
                              color={theme.palette.secondary.main}
                              size={8}
                            />
                          </div>
                        )
                      }
                      renderEndAdornment={(
                        {
                          circularProgress,
                          errorAndSuccessComponents,
                          endAdornmentIcons,
                          // closeIcon,
                          // dropDownIcon,
                        },
                        { loading, error, touched },
                      ) => (
                        <InputAdornment position="end">
                          {loading ? circularProgress : <></>}
                        </InputAdornment>
                      )}
                    />
                  </div>
                </Fragment>
              )}
            </Popper>
          </div>
        </ClickAwayListener>
        <Collapse in={!!helperText}>
          <div className={helperTextClasses.join(' ')}>{helperText}</div>
        </Collapse>
      </div>
    );
  },
);

export default memo(AsyncPopperAutoComplete);

const useStyles = makeStyles((theme) => ({
  Container: {
    '& *:not(.end-Adorn, .end-Adorn *)': {
      color: 'var(--color, #fff)',
      fontFamily: 'inherit',
    },
  },
  InputContainerReplacement: {
    width: '100%',
    height: theme.typography.pxToRem(50),
    border: `1px solid #fff`,
    borderRadius: theme.typography.pxToRem(10),
    padding: theme.spacing(0, theme.typography.pxToRem(14)),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    cursor: 'text',

    '&:focus': {
      backgroundColor: 'var(--backgroundColorOnFocus, #fff) !important',
      border: '1px solid var(--border-color, #000)',
      boxShadow: '0 0 0 4px var(--box-shadowColor, #000)',
    },

    '& > *': {
      padding: '0 0.1rem',
    },
  },
  ButtonFocused: {
    border: '1px solid var(--border-color, #000)',
    boxShadow: '0 0 0 4px var(--box-shadowColor, #000)',
  },
  InputBox: {
    flex: 1,
    font: 'inherit',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    textTransform: 'capitalize',
  },
  Popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    zIndex: 1,
    fontSize: theme.typography.pxToRem(13),
    color: '#586069',
    backgroundColor: '#f6f8fa',
    position: 'fixed',
  },
  NoAutoCompleteTextHeader: {
    padding: theme.spacing(
      theme.typography.pxToRem(16),
      theme.typography.pxToRem(20),
    ),
    lineHeight: 1,
    color: colors.grey[500],
    fontSize: theme.typography.pxToRem(16),
  },
  AutoCompleteHeader: {
    color: colors.grey[600],
    padding: theme.spacing(
      theme.typography.pxToRem(10),
      theme.typography.pxToRem(20),
    ),
  },
  AutoCompletePaper: {
    '& .MuiAutocomplete-listbox': {
      maxHeight: 'initial',
    },
  },
  HelperTextDiv: {
    fontSize: theme.typography.pxToRem(13),
    margin: theme.spacing(0.5, 0, 0, 2.5),
  },
  ErrorDiv: {
    color: theme.palette.error.main,
  },
}));
