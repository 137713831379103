import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  memo,
} from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import { alpha, colors, makeStyles } from '@material-ui/core';
import 'draft-js/dist/Draft.css';

import EditorControlsToolbar from './EditorControlsToolbar';
import HelperText from '../HelperText';
import { decorator } from './EntityControls';
import { generateBlocks } from './DynamicHTMLDisplay';
// import DynamicHTMLDisplay, { generateBlocks } from './DynamicHTMLDisplay';

const createEditorState = (value) => {
  value = value.trim();

  let editorState = EditorState.createEmpty(decorator);

  try {
    const JSONParsed = JSON.parse(value);
    const contentState = convertFromRaw(JSONParsed);

    editorState = EditorState.createWithContent(contentState, decorator);
  } catch (error) {
    if (value) {
      const blocksFromHTML = convertFromHTML(`<div>${value}</div>`);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );

      editorState = EditorState.createWithContent(contentState, decorator);
    }
  }

  return () => editorState;
};

const toRawData = (rawData) => {
  let noText = true;

  for (const block of rawData.blocks) {
    if (block.text.trim()) {
      noText = false;
      break;
    }
  }

  // return noText ? '' : JSON.stringify(rawData);
  return noText ? '' : generateBlocks(rawData?.blocks, rawData?.entityMap);
};

const RichTextEditor = forwardRef(
  (
    {
      onFocus,
      onBlur,
      value = '',
      onChange,
      placeholder,
      error = false,
      touched = false,
      helperText = '',
    },
    ref,
  ) => {
    const classes = useStyles();

    const editorRef = useRef();
    const timerRef = useRef(null);
    const doThrottle = useRef(true);

    const [editorState, setEditorState] = useState(createEditorState(value));
    const [rawData, setRawData] = useState(null);
    const [toolbarFocused, setToolbarFocused] = useState(false);
    const [focused, setFocused] = useState(false);

    // const generatedHTML = generateBlocks(rawData?.blocks, rawData?.entityMap);

    useImperativeHandle(ref, () => ({
      focus: onClickToolbarButtons,
      value: toRawData(rawData || { blocks: [{ text: '' }] }),
    }));

    useEffect(() => {
      if (doThrottle.current) {
        doThrottle.current = false;

        timerRef.current = setTimeout(() => {
          doThrottle.current = true;
        }, 500);

        const rawData = convertToRaw(editorState.getCurrentContent());
        setRawData(rawData);
        if (typeof onChange === 'function') {
          onChange(toRawData(rawData));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorState]);

    useEffect(() => {
      let timer = null;

      timer = setTimeout(() => {
        const rawData = convertToRaw(editorState.getCurrentContent());
        setRawData(rawData);
        if (typeof onChange === 'function') {
          onChange(toRawData(rawData));
        }
      }, 800);

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorState]);

    useEffect(() => {
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }, []);

    const onClickToolbarButtons = () => {
      editorRef.current.focus();
    };

    const toggleBlockType = (blockType) => {
      setEditorState((curState) =>
        RichUtils.toggleBlockType(curState, blockType),
      );
    };

    const toggleInlineStyle = (inlineStyle) => {
      setEditorState((curState) =>
        RichUtils.toggleInlineStyle(curState, inlineStyle),
      );
    };

    const confirmLink = (url) => {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url },
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });

      setEditorState(
        RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey,
        ),
      );
    };

    const removeLink = () => {
      const selection = editorState.getSelection();
      if (!selection.isCollapsed()) {
        setEditorState(RichUtils.toggleLink(editorState, selection, null));
      }
    };

    const handleKeyCommand = (command) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return true;
      }
      return false;
    };

    const onTab = (e) => {
      const maxDepth = 4;
      setEditorState(RichUtils.onTab(e, editorState, maxDepth));
    };

    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    const listCheck = !!(
      blockType === 'unordered-list-item' || blockType === 'ordered-list-item'
    );

    return (
      <div
        className={classes.EditorContainer}
        onMouseEnter={setToolbarFocused.bind(null, true)}
        onMouseLeave={
          focused
            ? setToolbarFocused.bind(null, true)
            : setToolbarFocused.bind(null, false)
        }
        role="textbox"
      >
        <EditorControlsToolbar
          editorState={editorState}
          toggleInlineStyle={toggleInlineStyle}
          toggleBlockType={toggleBlockType}
          toolbarFocused={toolbarFocused}
          editorFocused={focused}
          onClickToolbarButtons={onClickToolbarButtons}
          confirmLink={confirmLink}
          removeLink={removeLink}
        />

        <div
          className={[
            [classes.Editor, error ? classes.EditorError : ''].join(' '),
            focused || editorState.getCurrentContent().hasText() || listCheck
              ? [
                  classes.EditorFocused,
                  error
                    ? classes.EditorFocusedError
                    : touched
                    ? classes.EditorFocusedSuccess
                    : '',
                ].join(' ')
              : '',
          ].join(' ')}
        >
          <Editor
            // blockStyleFn={(block) => {
            //   switch (block.getType()) {
            //     case 'header-one':
            //     case 'header-two':
            //     case 'header-three':
            //     case 'header-four':
            //     case 'header-five':
            //     case 'header-six':
            //     case 'unordered-list-item':
            //     case 'ordered-list-item':
            //       return contentClasses[block.getType()];

            //     default:
            //       return null;
            //   }
            // }}
            // customStyleMap={styleMap}
            editorState={editorState}
            onChange={setEditorState}
            placeholder={listCheck ? '' : placeholder}
            handleKeyCommand={handleKeyCommand}
            onTab={onTab}
            spellCheck={true}
            ref={editorRef}
            onFocus={(e) => {
              if (typeof onFocus === 'function') {
                onFocus(e);
              }
              setToolbarFocused(true);
              setFocused(true);
            }}
            onBlur={(e) => {
              if (typeof onBlur === 'function') {
                onBlur(e);
              }
              setToolbarFocused(false);
              setFocused(false);
            }}
          />
        </div>
        <HelperText error={error}>{helperText}</HelperText>

        {/* <DynamicHTMLDisplay rawData={rawData} />
        <div>{generatedHTML}</div>
        <pre>{JSON.stringify(rawData, undefined, 2)}</pre> */}
      </div>
    );
  },
);

export default memo(RichTextEditor);

const useStyles = makeStyles((theme) => {
  const {
    palette,
    shape: { borderRadius },
    spacing,
    typography: { pxToRem },
  } = theme;

  const borderColor = colors.grey[300];
  const borderErrorColor = palette.error.main;
  const borderSuccessColor = palette.success.main;

  const borderWidth = 1;
  const errorAndSuccessBorderWidth = 2;

  return {
    EditorContainer: {},
    Editor: {
      padding: spacing(pxToRem(16), pxToRem(20)),
      background: '#f0f0f0',
      borderRadius,
      minHeight: pxToRem(150),
      borderColor: 'transparent',

      transition: 'border-color 300ms ease-in',
    },
    EditorError: {
      background: alpha(palette.error.main, 0.2),
    },
    EditorFocused: {
      background: '#fefefe',
      borderRadius: 0,
      borderColor,
      borderLeftWidth: borderWidth,
      borderRightWidth: borderWidth,
      borderStyle: 'solid',
      borderTopWidth: 0,
      borderBottomWidth: 0,
    },
    EditorFocusedError: {
      borderColor: borderErrorColor,
      borderLeftWidth: errorAndSuccessBorderWidth,
      borderRightWidth: errorAndSuccessBorderWidth,
    },
    EditorFocusedSuccess: {
      borderColor: borderSuccessColor,
      borderLeftWidth: errorAndSuccessBorderWidth,
      borderRightWidth: errorAndSuccessBorderWidth,
    },
  };
});

// const useContentStyles = () => {
//   const theme = useTheme();
//   const classes = contentStyles(theme);

//   return makeStyles(() => ({
//     'header-one': classes.ContentHeader1,
//     'header-two': classes.ContentHeader2,
//     'header-three': classes.ContentHeader3,
//     'header-four': classes.ContentHeader4,
//     'header-five': classes.ContentHeader5,
//     'header-six': classes.ContentHeader6,

//     'unordered-list-item': classes.UnorderedList,
//     'ordered-list-item': classes.OrderedList,
//   }));
// };
