import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ShowCustomizedEndAdornment = ({
  render = ({ errorAndSuccessComponents }) => (
    <InputAdornment position="end">{errorAndSuccessComponents}</InputAdornment>
  ),
  error = false,
  touched = false,
}) => {
  const theme = useTheme();

  const children = error ? (
    <ErrorIcon color="error" className="end-Adorn" />
  ) : touched ? (
    <CheckCircleIcon
      htmlColor={theme.palette.success.main}
      className="end-Adorn"
    />
  ) : (
    <></>
  );

  return render({ errorAndSuccessComponents: children });
};

export default ShowCustomizedEndAdornment;
