import React, { useEffect } from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@material-ui/core';
import { Route, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Booking from './pages/Booking';
import Profile from './pages/Profile';
import Packages from './pages/Packages';
import EditPackages from './pages/EditPackages';
import Login from './pages/Login';
import Header from './containers/Header';
import TransitionSwitch from './hoc/TransitionSwitch';
import routeDefinitions, { ROUTES } from './routes';
import { loginUsingSavedCredentials } from './store/thunks/auth-thunks';
import SnackBarHOC from './hoc/SnackBarHOC';
import HandleError from './hoc/HandleError';
import Initialize from './hoc/Initialize';
import useDialog from './hooks/useDialog';

const App = () => {
  const { pathname } = useLocation();

  const { networkFetching, loadingMessage } = useSelector((state) => state.ui);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const dispatch = useDispatch();

  const { dialogComponent, openDialog, closeDialog } = useDialog({
    loaderTemplate: true,
  });

  useEffect(() => {
    if (networkFetching) {
      openDialog(null, {
        autoCloseWhenBackdropClicked: false,
        icon: false,
        content: loadingMessage,
      });
    } else {
      closeDialog();
    }
  }, [networkFetching, loadingMessage, openDialog, closeDialog]);

  useEffect(() => {
    dispatch(loginUsingSavedCredentials());
  }, [dispatch]);

  const { path, loggedInRoutes } = routeDefinitions({ pathname, isLoggedIn });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {dialogComponent}
      <Header />
      <SnackBarHOC>
        <HandleError>
          <Initialize>
            <TransitionSwitch>
              {!isLoggedIn && (
                <Route
                  exact
                  path={[
                    '/login',
                    ...loggedInRoutes.map((rte) => `${rte}/login`),
                  ]}
                  component={Login}
                />
              )}
              {!isLoggedIn && <Redirect from="/" to={path} />}
              <Route exact path={ROUTES.BOOKING.path} component={Booking} />
              <Route exact path={ROUTES.PROFILE.path} component={Profile} />
              <Route exact path={ROUTES.PACKAGES.path} component={Packages} />
              <Route
                exact
                path={ROUTES.ADD_PACKAGES.path}
                component={EditPackages}
              />
              <Route
                exact
                path={`${ROUTES.EDIT_PACKAGES.path}/:package_id`}
                component={EditPackages}
              />
              <Redirect from="/" to={path} />
            </TransitionSwitch>
          </Initialize>
        </HandleError>
      </SnackBarHOC>
    </ThemeProvider>
  );
};

export default App;

export const theme = createTheme({
  palette: {
    primary: {
      main: '#202020',
      contrastText: '#fff',
    },
    secondary: {
      main: '#1021b4',
      contrastText: '#fff',
    },
    error: {
      main: '#f21616',
      contrastText: '#fff',
    },
    success: {
      main: '#09aa1c',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
    },
    info: {
      main: '#2900d8',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif'].join(','),
  },
});
