import { uiActions } from '../reducers/ui-slice';
import { authActions } from '../reducers/auth-slice';
import { profileDetailsActions } from '../reducers/profile-details-slice';
import axios from '../../axios';
import URIS from '../../api';
import {
  ACCESS_TOKEN,
  USER_DETAILS,
} from '../../global-constants';

export const loginUsingSavedCredentials = () => {
  return (dispatch) => {
    dispatch(
      uiActions.setLoading({
        networkFetching: true,
        loadingMessage: 'Logging you in',
      }),
    );

    const authTokenSavedInLocalStorage = localStorage.getItem(ACCESS_TOKEN);
    const userDetailsSavedInLocalStorage = localStorage.getItem(USER_DETAILS)
      ? JSON.parse(localStorage.getItem(USER_DETAILS))
      : null;

    if (!!authTokenSavedInLocalStorage) {
      dispatch(
        authActions.changeAuthState({
          authToken: authTokenSavedInLocalStorage,
        }),
      );

      dispatch(
        profileDetailsActions.setUserDetails(
          userDetailsSavedInLocalStorage || {},
        ),
      );
    } else {
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(USER_DETAILS);
    }

    dispatch(
      uiActions.setLoading({
        networkFetching: false,
        loadingMessage: null,
      }),
    );
  };
};

export const loginUsingEmailAndPassword = ({ username, password }) => {
  return async (dispatch) => {
    dispatch(
      uiActions.setLoading({
        networkFetching: true,
        loadingMessage: 'Logging you in',
      }),
    );

    const { data } = await axios.post(URIS.SIGN_IN_WITH_USERNAME_AND_PASSWORD, {
      username,
      password,
      login_type: 'session',
      user_type: '4',
    });

    if (data.statusCode === 200 || data.statusCode === 201) {
      dispatch(
        authActions.changeAuthState({
          authToken: data?.user?.id_token,
        }),
      );

      dispatch(profileDetailsActions.setUserDetails(data?.user || {}));

      localStorage.setItem(ACCESS_TOKEN, data?.user?.id_token);
      localStorage.setItem(USER_DETAILS, JSON.stringify(data?.user));
    }

    dispatch(
      uiActions.setLoading({
        networkFetching: false,
        loadingMessage: null,
      }),
    );
  };
};

export const logout = (idToken) => {
  return (dispatch) => {
    axios.post(URIS.LOGOUT, {
      id_token: idToken,
    });

    dispatch(authActions.logout());
    dispatch(profileDetailsActions.reset());
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER_DETAILS);
  };
};
