import React from 'react';
import { Collapse, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import RawTextField from './RawTextField';

const TextField = React.forwardRef(
  (
    {
      type = 'text',
      helperText = '',
      error = false,
      touched = false,
      variant = 'outlined',
      helperTextClassName = '',
      errorHelperTextClassName = '',
      showValidationDefault = true,
      renderEndAdornment = ({ errorAndSuccessComponents }) => (
        <InputAdornment position="end">
          {errorAndSuccessComponents}
        </InputAdornment>
      ),
      ...props
    },
    ref,
  ) => {
    const classes = useStyles();

    const helperTextClasses = [classes.HelperTextDiv, helperTextClassName];

    if (showValidationDefault) {
      if (error) {
        helperTextClasses.push(classes.ErrorDiv);
        helperTextClasses.push(errorHelperTextClassName);
      }
    }

    return (
      <div className={classes.TextFieldContainer}>
        <RawTextField
          ref={ref}
          type={type}
          error={error}
          touched={touched}
          variant={variant}
          showValidationDefault={showValidationDefault}
          renderEndAdornment={renderEndAdornment}
          {...props}
        />
        <Collapse in={!!helperText}>
          <div className={helperTextClasses.join(' ')}>{helperText}</div>
        </Collapse>
      </div>
    );
  },
);

export default TextField;
export { RawTextField };

const useStyles = makeStyles((theme) => ({
  TextFieldContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  HelperTextDiv: {
    fontSize: theme.typography.pxToRem(13),
    margin: theme.spacing(0.5, 0, 0, 2.5),
  },
  ErrorDiv: {
    color: theme.palette.error.main,
  },
}));
