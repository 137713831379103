import React from 'react';
import { Collapse, InputAdornment, InputBase } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';

import ShowCustomizedEndAdornment from './ShowCustomizedEndAdornment';

const Input = React.forwardRef(
  (
    {
      inputMode,
      type = 'text',
      helperText = '',
      error = false,
      touched = false,
      errorContainerClassName = '',
      renderEndAdornment = ({ errorAndSuccessComponents }) => (
        <InputAdornment position="end">
          {errorAndSuccessComponents}
        </InputAdornment>
      ),
      containerClassName = '',
      ...props
    },
    ref,
  ) => {
    const { classes: propsClasses = {} } = props;
    const classes = useStyles();

    const rootClassNames = [classes.InputBaseRoot];
    const focusedClassNames = [classes.InputBaseRootFocused];
    const errorClassNames = [classes.HelperTextDiv];

    if (error) {
      focusedClassNames.push(classes.InputBaseRootErrorFocused);
      errorClassNames.push(classes.ErrorDiv);
    } else if (touched) {
      rootClassNames.push(classes.InputBaseRootSuccess);
      focusedClassNames.push(classes.InputBaseRootSuccessFocused);
    }

    const passwordType = type === 'password' ? { type: 'password' } : {};

    return (
      <div className={[classes.InputContainer, containerClassName].join(' ')}>
        <InputBase
          inputMode={inputMode || type}
          {...passwordType}
          inputRef={ref}
          {...props}
          error={error}
          classes={{
            ...propsClasses,
            root: [...rootClassNames, propsClasses.root || ''].join(' '),
            input: [
              classes.Input,
              props?.startAdornment ? classes.InputWithStartAdornment : '',
              propsClasses.input || '',
            ].join(' '),
            focused: [...focusedClassNames, propsClasses.focused || ''].join(
              ' ',
            ),
            error: [classes.InputBaseRootError, propsClasses.error || ''].join(
              ' ',
            ),
            disabled: [
              classes.InputBaseDisabledRoot,
              propsClasses.disabled || '',
            ].join(' '),
            adornedEnd: [
              classes.InputRootAdornEnd,
              propsClasses.adornedEnd || '',
            ].join(' '),
          }}
          endAdornment={
            <ShowCustomizedEndAdornment
              error={error}
              touched={touched}
              render={renderEndAdornment}
            />
          }
        />
        <Collapse in={!!helperText}>
          <div
            className={[...errorClassNames, errorContainerClassName].join(' ')}
          >
            {helperText}
          </div>
        </Collapse>
      </div>
    );
  },
);

export default Input;
export { ShowCustomizedEndAdornment };

const useStyles = makeStyles((theme) => ({
  InputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  InputBaseRoot: {
    width: '100%',
    height: '50px',
    background: '#f0f0f0',
    color: '#000',
    borderRadius: '10px',
    border: '1px solid transparent',
    boxShadow: '0 0 0 4px transparent',
    overflow: 'hidden',
    transition: 'all 0.3s',

    '& > div': {
      padding: '0 0.8rem',
    },
  },
  InputBaseDisabledRoot: {
    '&:not(input)': {
      border: '2px dashed #cfcfcf',
      height: 40,
      width: 'calc(100% - 10px)',
      boxShadow: '0 0 0 5px #f0f0f0',
      marginLeft: 5,
      marginTop: 5,
      opacity: 0.7,
      filter: 'grayscale(1)',
    },

    '& > input': {
      padding: '0 15px',
    },
  },
  InputBaseRootFocused: {
    background: '#fefefe',
    border: `1px solid ${theme.palette.secondary.main}`,
    boxShadow: `0 0 0 4px ${alpha(theme.palette.secondary.main, 0.3)}`,
  },
  InputBaseRootError: {
    background: alpha(theme.palette.error.main, 0.2),
    color: '#000',
  },
  InputBaseRootErrorFocused: {
    border: `1px solid ${theme.palette.error.main}`,
    boxShadow: `0 0 0 4px ${alpha(theme.palette.error.main, 0.3)}`,
    background: '#fefefe',
  },
  InputBaseRootSuccess: {
    background: alpha(theme.palette.success.main, 0.2),
    color: '#000',
  },
  InputBaseRootSuccessFocused: {
    border: `1px solid ${theme.palette.success.main}`,
    boxShadow: `0 0 0 4px ${alpha(theme.palette.success.main, 0.3)}`,
    background: '#fefefe',
  },
  InputRootAdornEnd: {
    paddingRight: 0,

    '& > .MuiInputAdornment-positionEnd': {
      position: 'absolute',
      right: 14,
    },

    '& .MuiInputBase-inputAdornedEnd': {
      padding: theme.spacing(
        0,
        theme.typography.pxToRem(50),
        0,
        theme.typography.pxToRem(20),
      ),
    },
  },
  Input: {
    width: '100%',
    height: '100%',
    padding: '0px 20px',
    transition: 'all 0.3s',
  },
  InputWithStartAdornment: {
    '&.MuiInputBase-input': {
      paddingLeft: '0',
    },
  },
  HelperTextDiv: {
    fontSize: theme.typography.pxToRem(13),
    margin: theme.spacing(0.5, 0, 0, 2.5),
  },
  ErrorDiv: {
    color: theme.palette.error.main,
  },
}));
