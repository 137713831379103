import { profileDetailsActions } from '../reducers/profile-details-slice';
import { uiActions } from '../reducers/ui-slice';
import axios from '../../axios';
import URIS from '../../api';
import { USER_DETAILS } from '../../global-constants';

export const fetchCompleteUserDetails = ({ showLoader = false }) => {
  return async (dispatch) => {
    if (showLoader) {
      dispatch(
        uiActions.setLoading({
          networkFetching: true,
          loadingMessage: 'Fetching your details',
        }),
      );
    }
    dispatch(
      profileDetailsActions.setFetchingUserDetails({
        loading: true,
        errorMessage: null,
      }),
    );

    const userDetailsSavedInLocalStorage = localStorage.getItem(USER_DETAILS)
      ? JSON.parse(localStorage.getItem(USER_DETAILS))
      : null;

    try {
      const { data } = await axios.post(URIS.VIEW_HOSPITAL_DETAILS, {});

      if (data.statusCode === 200 || data.statusCode === 201) {
        dispatch(
          profileDetailsActions.setUserDetails(data?.response_data || {}),
        );

        localStorage.setItem(
          USER_DETAILS,
          JSON.stringify({
            ...userDetailsSavedInLocalStorage,
            ...(data?.response_data || {}),
          }),
        );

        dispatch(
          profileDetailsActions.setFetchingUserDetails({
            loading: false,
            errorMessage: null,
          }),
        );
      } else {
        throw new Error('something-went-wrong');
      }
    } catch (error) {
      dispatch(
        profileDetailsActions.setFetchingUserDetails({
          loading: false,
          errorMessage: "Couldn't fetch the hospital data!",
        }),
      );
    }

    dispatch(
      uiActions.setLoading({
        networkFetching: false,
        loadingMessage: null,
      }),
    );
  };
};
