import React, { forwardRef } from 'react';
import { Button, colors, makeStyles } from '@material-ui/core';

const StyleButton = forwardRef(
  ({ onToggle, style, active, children, label, block = false }, ref) => {
    const classes = useStyles();

    const handleMouseDown = (e) => {
      e.preventDefault();
      onToggle(style);
    };

    return (
      <Button
        className={[
          'buttonStyle-richText',
          classes.buttonStyle,
          active
            ? ['buttonStyleActive-richText', classes.activeButtonStyle].join(
                ' ',
              )
            : '',
        ].join(' ')}
        onMouseDown={handleMouseDown}
        {...(!!style || !!block
          ? {
              'data-type': 'richtext-control',
              'data-style': style,
              'data-block': block,
            }
          : {})}
        innerRef={ref}
      >
        {children || <span>{label}</span>}
      </Button>
    );
  },
);

export default StyleButton;

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    color: colors.grey[600],
    cursor: 'pointer',
    padding: theme.spacing(0.4, 2),
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 0,

    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },

    '& *': {
      pointerEvents: 'none',
    },
  },
  activeButtonStyle: {
    color: theme.palette.secondary.main,
  },
}));
