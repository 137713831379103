import React, {
  useRef,
  useState,
  useReducer,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { useMediaQuery, useTheme, makeStyles, Box } from '@material-ui/core';
import produce from 'immer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LazyLoad from 'react-lazyload';
import { PulseLoader } from 'react-spinners';

import Button from '../components/Button';

const TEMPLATES_AVAILABLE = {
  DEFAULT: 'default',
  LOAD_MORE_BUTTON: 'load-more-button',
  LAZY_LOAD: 'lazy-load',
};

const LOADING_STATE = {
  LOADING: 'loading',
  LOADED: 'loaded',
};

const OnInitFunctionPlayOnce = ({ onLoad }) => {
  const initRef = useRef(false);

  useEffect(() => {
    if (onLoad && !initRef.current) {
      initRef.current = true;
      onLoad();
    }
  }, [onLoad]);

  return null;
};

const paginationReducer = produce(
  (
    state = {
      dataChunks: [],
      loadedPages: [],
      currentPage: 0,
      totalNoOfPages: 1,
    },
    action,
  ) => {
    switch (action.type) {
      case 'SET_DATA_CHUNKS':
        if (!state.loadedPages.includes(action.payload.loadedPageNo)) {
          state.loadedPages.push(action.payload.loadedPageNo);
        }

        for (let i = 0; i < action.payload.dataSlice.length; i++) {
          state.dataChunks[action.payload.startIndex + i] =
            action.payload.dataSlice[i];
        }

        for (
          let i = action.payload.endIndex - 1;
          i >= action.payload.startIndex;
          i--
        ) {
          if (state.dataChunks[i] === LOADING_STATE.LOADING) {
            // state.dataChunks.splice(i, 1);
            state.dataChunks[i] = LOADING_STATE.LOADED;
          }
        }
        return;

      case 'SET_LOADING_DATA_CHUNKS':
        for (
          let i = action.payload.startIndex;
          i < action.payload.endIndex;
          i++
        ) {
          state.dataChunks[i] = LOADING_STATE.LOADING;
        }
        return;

      case 'SET_DATA':
        state.dataChunks[action.payload.index] = action.payload.newData;
        return;

      case 'REMOVE_DATA':
        if (typeof action.payload.matches === 'function') {
          state.dataChunks.splice(
            state.dataChunks.findIndex((item) => action.payload.matches(item)),
            1,
          );
        } else if (typeof action.payload.matches === 'number') {
          state.dataChunks.splice(action.payload.index, 1);
        }
        return;

      case 'SET_CURRENT_PAGE':
        state.currentPage = action.payload;
        return;

      case 'SET_TOTAL_NO_OF_PAGES':
        state.totalNoOfPages = action.payload;
        return;

      case 'ERROR':
        state.totalNoOfPages = state.currentPage;
        state.dataChunks.forEach((item, index) => {
          if (item === LOADING_STATE.LOADING) {
            state.dataChunks[index] = LOADING_STATE.LOADED;
          }
        });
        return;

      case 'RESET_PAGINATION':
        state.dataChunks = [];
        state.loadedPages = [];
        state.currentPage = 0;
        state.totalNoOfPages = 1;
        return;

      default:
        return {
          dataChunks: [],
          loadedPages: [],
          currentPage: 0,
          totalNoOfPages: 1,
        };
    }
  },
);

const deriveTemplate = ({ template, touchScreenTemplate, touchScreen }) => {
  const checkTemplate = (templateForChecking) => {
    return (
      (template === templateForChecking &&
        (!touchScreen || !touchScreenTemplate)) ||
      (touchScreen && touchScreenTemplate === templateForChecking)
    );
  };

  return checkTemplate(TEMPLATES_AVAILABLE.DEFAULT)
    ? TEMPLATES_AVAILABLE.DEFAULT
    : checkTemplate(TEMPLATES_AVAILABLE.LOAD_MORE_BUTTON)
    ? TEMPLATES_AVAILABLE.LOAD_MORE_BUTTON
    : checkTemplate(TEMPLATES_AVAILABLE.LAZY_LOAD)
    ? TEMPLATES_AVAILABLE.LAZY_LOAD
    : TEMPLATES_AVAILABLE.DEFAULT;
};

const usePagination = ({
  onLoad = true,
  template = TEMPLATES_AVAILABLE.DEFAULT,
  touchScreenTemplate = TEMPLATES_AVAILABLE.LAZY_LOAD,
  customComponent = null,
  minMargin = 0,
  loadData = () => {},
  rowsPerBatch = 10,
} = {}) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesUpSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const matchesUpMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const matchesUp390p = useMediaQuery('(min-width: 390px)');
  const touchScreen = useMediaQuery('(hover: none) and (pointer: coarse)');

  const pagingTemplate = deriveTemplate({
    template,
    touchScreenTemplate,
    touchScreen,
  });

  const [paginationState, dispatchPaginationState] = useReducer(
    paginationReducer,
    {
      dataChunks: [],
      loadedPages: [],
      currentPage: 0,
      totalNoOfPages: 1,
    },
  );

  const containerRef = useRef(null);
  const initApiCallRef = useRef(!onLoad);

  const [loadingFirstTime, setLoadingFirstTime] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageChangedTo, setPageChangedTo] = useState(1);

  const refreshPaginatedList = useCallback(() => {
    initApiCallRef.current = false;
    dispatchPaginationState({
      type: 'RESET_PAGINATION',
    });
  }, []);

  const loadNextRow = useCallback(
    async ({ pageNo = null, freshData = false } = {}) => {
      if (
        paginationState.currentPage === paginationState.totalNoOfPages &&
        template !== TEMPLATES_AVAILABLE.DEFAULT
      ) {
        return;
      }

      if (
        !freshData &&
        paginationState.loadedPages.includes(
          pageNo || paginationState.currentPage + 1,
        )
      ) {
        return;
      }

      dispatchPaginationState({
        type: 'SET_LOADING_DATA_CHUNKS',
        payload: {
          startIndex:
            ((pageNo || paginationState.currentPage + 1) - 1) * rowsPerBatch,
          endIndex: (pageNo || paginationState.currentPage + 1) * rowsPerBatch,
        },
      });

      setLoading(true);
      try {
        let { dataList, currentPage, rowsPerPage, totalPagesCount } =
          await loadData({
            pageNo: pageNo || paginationState.currentPage + 1,
            rowsPerPage: rowsPerBatch,
          });

        if (
          !Array.isArray(dataList) ||
          isNaN(parseInt(currentPage)) ||
          isNaN(parseInt(rowsPerPage)) ||
          isNaN(parseInt(totalPagesCount))
        ) {
          throw new Error('something-went-wrong');
        }

        currentPage = parseInt(currentPage);
        rowsPerPage = parseInt(rowsPerPage);
        totalPagesCount = parseInt(totalPagesCount);

        dispatchPaginationState({
          type: 'SET_DATA_CHUNKS',
          payload: {
            startIndex:
              ((pageNo || paginationState.currentPage + 1) - 1) * rowsPerBatch,
            endIndex:
              (pageNo || paginationState.currentPage + 1) * rowsPerBatch,
            dataSlice: dataList,
            loadedPageNo: currentPage,
          },
        });

        dispatchPaginationState({
          type: 'SET_TOTAL_NO_OF_PAGES',
          payload: totalPagesCount,
        });
        dispatchPaginationState({
          type: 'SET_CURRENT_PAGE',
          payload: currentPage,
        });
      } catch (error) {
        dispatchPaginationState({ type: 'ERROR' });
      }
      setLoading(false);

      return null;
    },
    [
      paginationState.currentPage,
      paginationState.totalNoOfPages,
      paginationState.loadedPages,
      template,
      rowsPerBatch,
      loadData,
    ],
  );

  useEffect(() => {
    if (
      !initApiCallRef.current &&
      paginationState.currentPage !== paginationState.totalNoOfPages
    ) {
      setLoadingFirstTime(true);
      loadNextRow().finally((_) => {
        setLoadingFirstTime(false);
      });
      initApiCallRef.current = true;
    }
  }, [
    loadNextRow,
    paginationState.currentPage,
    paginationState.totalNoOfPages,
  ]);

  const updateListData = useCallback((index, newData) => {
    dispatchPaginationState({
      type: 'SET_DATA',
      payload: {
        index,
        newData,
      },
    });
  }, []);

  const removeFlatListData = useCallback(({ index, matches }) => {
    dispatchPaginationState({
      type: 'REMOVE_DATA',
      payload: {
        index,
        matches,
      },
    });
  }, []);

  const paginatedDataChunks = useMemo(
    () =>
      paginationState.dataChunks.filter(
        (item) => item !== LOADING_STATE.LOADED,
      ),
    [paginationState.dataChunks],
  );

  return {
    List: ({
      children = (props) => <div {...props} />,
      LoadingListItem = (props) => <div {...props} />,
      NoDataFoundComponent = null,
      lazyLoadOffset = 100,
      lazyLoadHeight = 100,
      lazyLoadNextRowOffset = null,
    }) => {
      return (
        <React.Fragment>
          <ul
            className={classes.ListGroup}
            style={{ margin: minMargin }}
            ref={containerRef}
          >
            {[
              ...(loadingFirstTime
                ? Array.from({ length: rowsPerBatch }, (_, index) => (
                    <li className={classes.ListItemRow} key={index}>
                      <LoadingListItem
                        lastColumn={index + 1 === rowsPerBatch}
                      />
                    </li>
                  ))
                : paginatedDataChunks.length <= 0
                ? [
                    typeof NoDataFoundComponent === 'function' ? (
                      <NoDataFoundComponent key="no-data-found" />
                    ) : (
                      <Box
                        key="no-data-found"
                        height={theme.typography.pxToRem(124)}
                        width="100%"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: theme.typography.pxToRem(16),
                        }}
                      >
                        {typeof NoDataFoundComponent === 'string'
                          ? NoDataFoundComponent
                          : 'No Records Found!'}
                      </Box>
                    ),
                  ]
                : pagingTemplate === TEMPLATES_AVAILABLE.DEFAULT
                ? Array.from({ length: rowsPerBatch }, (_, index) => {
                    const currentColumn =
                      (pageChangedTo - 1) * rowsPerBatch + index;

                    const currentRowItem =
                      paginationState.dataChunks[currentColumn];

                    return !currentRowItem ||
                      currentRowItem === LOADING_STATE.LOADED ? null : (
                      <li key={`${JSON.stringify(currentRowItem)}${index}`}>
                        {currentRowItem === LOADING_STATE.LOADING ? (
                          <LoadingListItem
                            lastColumn={rowsPerBatch === index + 1}
                          />
                        ) : (
                          children({
                            ...currentRowItem,
                            updateListData: updateListData.bind(
                              null,
                              currentColumn,
                            ),
                            rowIndex: index,
                            lastColumn: rowsPerBatch === index + 1,
                          })
                        )}
                      </li>
                    );
                  })
                : pagingTemplate === TEMPLATES_AVAILABLE.LOAD_MORE_BUTTON ||
                  pagingTemplate === TEMPLATES_AVAILABLE.LAZY_LOAD
                ? Array.from(
                    { length: rowsPerBatch * pageChangedTo },
                    (_, index) => {
                      const currentRowItem = paginationState.dataChunks[index];

                      return !currentRowItem ||
                        currentRowItem === LOADING_STATE.LOADED ? null : (
                        <LazyLoad
                          key={`${JSON.stringify(currentRowItem)}${index}`}
                          offset={lazyLoadOffset}
                          once={false}
                          height={lazyLoadHeight}
                          overflow
                          scroll
                          resize
                          unmountIfInvisible
                        >
                          {currentRowItem === LOADING_STATE.LOADING ? (
                            <LoadingListItem
                              lastColumn={
                                rowsPerBatch * pageChangedTo === index + 1
                              }
                            />
                          ) : (
                            children({
                              ...currentRowItem,
                              updateListData: updateListData.bind(null, index),
                              rowIndex: index,
                              lastColumn:
                                rowsPerBatch * pageChangedTo === index + 1,
                            })
                          )}
                        </LazyLoad>
                      );
                    },
                  )
                : null),
              pagingTemplate === TEMPLATES_AVAILABLE.LAZY_LOAD &&
              paginationState.totalNoOfPages !== paginationState.currentPage &&
              paginationState.totalNoOfPages > 1 ? (
                <LazyLoad
                  key={`${pageChangedTo}${paginationState.totalNoOfPages}`}
                  offset={lazyLoadNextRowOffset || lazyLoadOffset}
                  once={false}
                  height={10}
                  overflow
                  scroll
                  resize
                  unmountIfInvisible
                >
                  <OnInitFunctionPlayOnce
                    onLoad={() => {
                      setPageChangedTo((curPage) => ++curPage);
                      loadNextRow();
                    }}
                  />
                </LazyLoad>
              ) : null,
            ]}
          </ul>
          {pagingTemplate === TEMPLATES_AVAILABLE.DEFAULT && (
            <div
              style={{
                padding: theme.spacing(
                  matchesUpMd
                    ? theme.typography.pxToRem(80)
                    : matchesUpSm
                    ? theme.typography.pxToRem(60)
                    : theme.typography.pxToRem(40),
                  matchesUp390p
                    ? theme.typography.pxToRem(15)
                    : theme.typography.pxToRem(10),
                ),
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {paginationState.totalNoOfPages !== 0 &&
                paginationState.totalNoOfPages > 1 &&
                (React.isValidElement(customComponent) ? (
                  customComponent
                ) : (
                  <Pagination
                    count={paginationState.totalNoOfPages}
                    size={
                      matchesUpMd ? 'large' : matchesUpSm ? 'medium' : 'small'
                    }
                    showFirstButton={matchesUp390p}
                    showLastButton={matchesUp390p}
                    color="primary"
                    disabled={loadingFirstTime}
                    page={pageChangedTo}
                    onChange={(event, pageNumber) => {
                      setPageChangedTo(pageNumber);
                      loadNextRow({ pageNo: pageNumber });
                    }}
                  />
                ))}
            </div>
          )}
          {pagingTemplate === TEMPLATES_AVAILABLE.LOAD_MORE_BUTTON && (
            <div
              style={{
                padding: theme.spacing(
                  matchesUpMd
                    ? theme.typography.pxToRem(40)
                    : matchesUpSm
                    ? theme.typography.pxToRem(30)
                    : theme.typography.pxToRem(20),
                  matchesUp390p
                    ? theme.typography.pxToRem(15)
                    : theme.typography.pxToRem(10),
                ),
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {paginationState.totalNoOfPages !== paginationState.currentPage &&
                paginationState.totalNoOfPages > 1 &&
                (React.isValidElement(customComponent) ? (
                  customComponent
                ) : (
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                      padding: theme.spacing(
                        theme.typography.pxToRem(5),
                        loading
                          ? theme.typography.pxToRem(20)
                          : theme.typography.pxToRem(10),
                        theme.typography.pxToRem(5),
                        theme.typography.pxToRem(20),
                      ),
                      borderRadius: 2000,
                    }}
                    onClick={() => {
                      setPageChangedTo((curPage) => ++curPage);
                      loadNextRow();
                    }}
                    disabled={loadingFirstTime || loading}
                  >
                    {loading ? (
                      <div
                        style={{
                          width: theme.typography.pxToRem(80),
                          display: 'inline-block',
                        }}
                      >
                        <PulseLoader
                          color={theme.palette.secondary.main}
                          size={10}
                        />
                      </div>
                    ) : (
                      <React.Fragment>
                        {typeof customComponent === 'string'
                          ? customComponent
                          : 'Load More'}{' '}
                        <ExpandMoreIcon
                          style={{ marginLeft: theme.typography.pxToRem(5) }}
                        />
                      </React.Fragment>
                    )}
                  </Button>
                ))}
            </div>
          )}
        </React.Fragment>
      );
    },
    dataChunks: paginatedDataChunks,
    currentPage: paginationState.currentPage,
    totalNoOfPages: paginationState.totalNoOfPages,
    loadedPages: paginationState.loadedPages,
    refreshPaginatedList,
    updateListData,
    removeFlatListData,
    loadNextRow,
  };
};

export default usePagination;
export { TEMPLATES_AVAILABLE };

const useStyles = makeStyles((theme) => ({
  ListGroup: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },

  ListItemRow: {
    margin: 0,
    padding: 0,
  },
}));
