import React from 'react';
import { InputAdornment } from '@material-ui/core';

import TextField from '../TextField';
import IndiaIcon from '../../icons/India';

const PhoneNumberField = React.forwardRef(
  (
    {
      value = '',
      InputProps: {
        startAdornment = ({ indiaIcon, plus91 }) =>
          value ? (
            <InputAdornment position="start">
              {indiaIcon}
              {plus91}
            </InputAdornment>
          ) : null,
        ...restInputProps
      } = {
        startAdornment: ({ indiaIcon, plus91 }) =>
          value ? (
            <InputAdornment position="start">
              {indiaIcon}
              {plus91}
            </InputAdornment>
          ) : null,
      },
      ...props
    },
    ref,
  ) => {
    return (
      <TextField
        InputProps={{
          ...restInputProps,
          startAdornment: startAdornment({
            indiaIcon: <IndiaIcon />,
            plus91: <span style={{ marginLeft: '0.5rem' }}>+91</span>,
          }),
        }}
        ref={ref}
        value={value}
        {...props}
        type="tel"
      />
    );
  },
);

export default PhoneNumberField;
