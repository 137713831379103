import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Grid, Box, makeStyles, useTheme, colors } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Button from '../components/Button';
import Page from '../hoc/Page';
import PackageItem from '../containers/PackageItem';
import SearchStrip from '../containers/SearchStrip';
import usePagination, { TEMPLATES_AVAILABLE } from '../hooks/usePagination';
import useApiCall from '../hooks/useApiCall';
import URIS from '../api';
import useDialog from '../hooks/useDialog';

const Packages = () => {
  const classes = useStyles();
  const theme = useTheme();

  const inputTimeoutRef = useRef(null);
  const initRef = useRef(false);

  const [inputChanged, setInputChanged] = useState(false);
  const [pattern, setPattern] = useState('');

  const { myFetch } = useApiCall({ listTemplate: true });

  const {
    dialogComponent: confirmationDialog,
    openDialog: openConfirmationDialog,
  } = useDialog({
    confirmationTemplete: true,
  });

  const { List, refreshPaginatedList, removeFlatListData } = usePagination({
    template: TEMPLATES_AVAILABLE.LOAD_MORE_BUTTON,
    rowsPerBatch: 10,
    loadData: useCallback(
      async ({ pageNo, rowsPerPage }) => {
        const data = await myFetch({
          url: URIS.LIST_PACKAGES,
          payload: {
            pattern,
            table_config_rows_per_page: `${rowsPerPage}`,
            table_config_page_no: `${pageNo}`,
          },
        });

        return {
          dataList: data?.response.response_packages,
          currentPage: data?.current_page,
          rowsPerPage: data?.rows_per_page,
          totalPagesCount: data?.total_pages_count,
        };
      },
      [myFetch, pattern],
    ),
  });

  useEffect(() => {
    if (initRef.current) {
      refreshPaginatedList();
    }

    initRef.current = true;
  }, [pattern, refreshPaginatedList]);

  const onInputChange = (value) => {
    if (inputTimeoutRef.current) {
      clearTimeout(inputTimeoutRef.current);
    }

    setInputChanged(true);

    inputTimeoutRef.current = setTimeout(() => {
      setPattern(value);
      setInputChanged(false);
    }, 2000);
  };

  const onDeleteItem = (props) => {
    removeFlatListData({
      matches: (item) => props?.package_id === item?.package_id,
    });
  };

  return (
    <Page>
      <Box className={classes.PageContent}>
        {confirmationDialog}
        <SearchStrip onInputChange={onInputChange} searching={inputChanged} />
        <Grid
          container
          style={{ paddingTop: 30 }}
          className={classes.MainBodyContent}
        >
          <Grid item container justifyContent="flex-end" xs={12}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.AddPackageBtn}
                type="submit"
                component={forwardRef((props, ref) => (
                  <Link to="/add-packages" innerRef={ref} {...props} />
                ))}
              >
                Add Packages
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={11}
            style={{
              margin: theme.spacing(
                theme.typography.pxToRem(24),
                'auto',
                theme.typography.pxToRem(50),
                'auto',
              ),
            }}
          >
            {/* <PackageItem
              {...{
                package_name: 'Malaadddr rapid test 234',
                region: 'West Bengal',
                description: 'covishield vaccine for covid vaccine',
                validity: '30',
                selling_credits: '11000.0',
                package_id: '4104e6b3-00a6-43bf-8d40-13cab1d3cde2',
                package_logo:
                  'https://files-carenowdoc-com-official-website-development.s3.ap-south-1.amazonaws.com/careplix/hospital/packages/4104e6b3-00a6-43bf-8d40-13cab1d3cde2/profile_photo/bus_image2021-08-1914:15:25.516786.jpg',
                hospital_credits: '10000.0',
                category: 'Health',
              }}
            /> */}
            <List
              LoadingListItem={({ lastColumn }) => <PackageItem loading />}
              NoDataFoundComponent={(props) => (
                <div
                  {...props}
                  style={{
                    fontWeight: 500,
                    fontSize: theme.typography.pxToRem(25),
                    height: theme.typography.pxToRem(200),
                    padding: theme.spacing(
                      theme.typography.pxToRem(50),
                      theme.typography.pxToRem(20),
                    ),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: colors.grey[400],
                  }}
                >
                  No Doctors found!
                </div>
              )}
              lazyLoadHeight={245}
              lazyLoadOffset={300}
              lazyLoadNextRowOffset={100}
            >
              {({ colIndex, rowIndex, lastColumn, ...props }) => (
                <PackageItem
                  {...props}
                  onDelete={onDeleteItem.bind(null, props)}
                  openConfirmationDialog={openConfirmationDialog}
                />
              )}
            </List>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Packages;

const useStyles = makeStyles((theme) => ({
  PageContent: {
    margin: theme.spacing(
      theme.typography.pxToRem(80),
      'auto',
      theme.typography.pxToRem(50),
      'auto',
    ),
  },
  MainBodyContent: {
    width: '90%',
    maxWidth: theme.typography.pxToRem(1920),
    margin: '0px auto',
    '@media (max-width:780px)': {
      width: '100%',
      maxWidth:'100% !important'
    },
  },
  AddPackageBtn: {
    padding: theme.spacing(
      theme.typography.pxToRem(8),
      theme.typography.pxToRem(40),
    ),
  },
}));
