import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  ButtonBase,
  Chip,
  colors,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Switch,
  useTheme,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import produce from 'immer';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PinDropIcon from '@material-ui/icons/PinDrop';
import CategoryIcon from '@material-ui/icons/Category';
import LabelIcon from '@material-ui/icons/Label';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

import Button from '../../components/Button';
import TextField from '../../components/TextField';
import HelperText from '../../components/HelperText';
import PopperAutocomplete from '../../components/Autocomplete/PopperAutocomplete';
import AsyncPopperAutoComplete from '../../components/Autocomplete/AsyncPopperAutocomplete';
import FieldController from './FieldController';
import { DOUBLE_WHITESPACES, VALIDATE_TAGS } from '../../utility/regex';
import IndianRupee from '../../icons/IndianRupee';
import indianStateList from '../../utility/indian-state-list';
import useApiCall from '../../hooks/useApiCall';
import URIS, {
  setAvoidErrorChecks,
  removeURLfromAvoidErrorChecks,
} from '../../api';
import useSnackbar from '../../hooks/useSnackbar';
import RichTextEditor from '../../components/RichTextEditor';

const indianUpdatedStateList = [
  ...indianStateList,
  {
    id: '0.1',
    name: 'All',
    country_id: '101',
  },
];

const getName = (option) => option?.name;
const getHospitalName = (option) => option?.hospital_name;

const tagExtractor = (tagString) => {
  return tagString
    .trim()
    .split(',')
    .map((item) => item.trim())
    .filter((item) => item !== '');
};

const AddPackagesForm = ({ editInfo, packageId }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { replace } = useHistory();

  const [selectedHospitalName, setSelectedHospitalName] = useState(
    typeof editInfo?.name === 'string' ? editInfo.name : '',
  );
  const [selectedCategory, setSelectedCategory] = useState(
    typeof editInfo?.category === 'string' ? editInfo.category : '',
  );
  const [image, setImage] = useState(null);
  const [imageErrorMessage, setImageErrorMessage] = useState(null);

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const { myFetch } = useApiCall();

  const fetchCategoryList = useCallback(
    async ({ pattern }) => {
      setAvoidErrorChecks({ url: URIS.LIST_PACKAGE_CATEGORIES });
      try {
        const data = await myFetch({
          url: URIS.LIST_PACKAGE_CATEGORIES,
          payload: {
            pattern,
            category_type: 'packages',
            api_type: 'all',
            table_config_rows_per_page: '20',
            table_config_page_no: '1',
            mode: 'all',
          },
        });

        removeURLfromAvoidErrorChecks({ url: URIS.LIST_PACKAGE_CATEGORIES });
        return Array.isArray(data?.response) ? data.response : [];
      } catch (error) {
        removeURLfromAvoidErrorChecks({ url: URIS.LIST_PACKAGE_CATEGORIES });
        return [];
      }
    },
    [myFetch],
  );

  const fetchHospitalList = useCallback(
    async ({ pattern }) => {
      setAvoidErrorChecks({ url: URIS.LIST_HOSPITALS });
      try {
        const data = await myFetch({
          url: URIS.LIST_HOSPITALS,
          payload: {
            pattern,
            table_config_rows_per_page: '20',
            table_config_page_no: '1',
          },
        });

        removeURLfromAvoidErrorChecks({ url: URIS.LIST_HOSPITALS });
        return Array.isArray(data?.response) ? data.response : [];
      } catch (error) {
        removeURLfromAvoidErrorChecks({ url: URIS.LIST_HOSPITALS });
        return [];
      }
    },
    [myFetch],
  );

  const initialValues =
    editInfo && typeof editInfo === 'object'
      ? Object.entries(editInfo).reduce(
          produce((prevValue, [key, value]) => {
            switch (key) {
              case 'package_name':
              case 'description':
              case 'discounts':
              case 'hospital_id':
              case 'region':
              case 'validity':
              case 'is_physical':
              case 'sponsored':
                prevValue[key] = value.toString();
                return;

              case 'tags_name':
                prevValue['tags'] = (Array.isArray(value) ? value : []).join(
                  ', ',
                );
                return;

              case 'category_id':
                prevValue['parent_category'] = value.toString();
                return;

              case 'hospital_price':
                prevValue['hospital_cost'] = value.toString();
                return;

              case 'selling_price':
                prevValue['selling_cost'] = value.toString();
                return;

              default:
                return prevValue;
            }
          }),
          {},
        )
      : {
          picture: '',
          picture_name: '',
          package_name: '',
          parent_category: '',
          description: '',
          hospital_cost: '',
          selling_cost: '',
          discounts: '',
          hospital_id: '',
          region: '',
          validity: '',
          tags: '',
          is_physical: '0',
          sponsored: '0',
        };

  const activeStyle = {
    borderColor: theme.palette.secondary.main,
  };

  const acceptStyle = {
    borderColor: theme.palette.success.main,
  };

  const rejectStyle = {
    borderColor: theme.palette.error.main,
  };

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { trigger, setValue, watch, handleSubmit, formState } = methods;

  const tagsValue = watch('tags');

  const { tags: tagsTouched = false } = formState.touchedFields;

  useEffect(() => {
    if (!tagsTouched) {
      trigger('tags');
    }
  }, [trigger, tagsValue, tagsTouched]);

  const onDrop = (acceptedFiles, fileRejections) => {
    if (!!fileRejections && fileRejections.length > 0) {
      setImageErrorMessage(
        fileRejections[0].errors[0].code === 'file-too-large'
          ? "File shouldn't be larger than 4.5 MB"
          : fileRejections[0].errors[0].message,
      );

      setImage(null);

      setValue('picture', '');
      setValue('picture_name', '');
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(acceptedFiles[0]);
      fileReader.onload = () => {
        setImageErrorMessage(null);
        setImage(fileReader.result);

        const fileName = acceptedFiles[0]?.name.replaceAll(
          /[^A-Za-z0-9-_.]+/g,
          '',
        );
        const base64 = fileReader.result.substring(
          fileReader.result.indexOf(',') + 1,
        );

        setValue('picture', base64);
        setValue(
          'picture_name',
          fileName.split('.')[fileName.split('.').length - 1],
        );
      };

      fileReader.onerror = (error) => {
        setImageErrorMessage('Image Parsing Error! Please try again...');

        setImage(null);
        setValue('picture', '');
        setValue('picture_name', '');
      };
    }
  };

  const onSubmit = async (values) => {
    if (
      packageId &&
      !formState.isDirty &&
      Object.entries(formState.dirtyFields).length <= 0 &&
      !values.picture
    ) {
      showErrorSnackbar('There are no new changes!');
      return;
    }

    const submittedValues = Object.entries(values).reduce(
      produce((prevValue, [key, value]) => {
        switch (key) {
          case 'tags':
            const extractedTags = tagExtractor(value);
            prevValue[key] = extractedTags.length <= 0 ? '' : extractedTags;
            return;

          case 'picture_name':
            prevValue[key] =
              typeof values.picture === 'string' && values.picture.trim()
                ? `${values.package_name.replaceAll(
                    /[^A-Za-z0-9-_.]+/g,
                    '',
                  )}.${value}`
                : '';
            return;

          default:
            prevValue[key] = (
              typeof value === 'string' ? value : value.toString()
            ).trim();
            return;
        }
      }),
      {},
    );

    try {
      if (!packageId) {
        await myFetch({
          url: URIS.ADD_PACKAGES,
          payload: submittedValues,
          loadingMessage: 'Adding the entered package',
        });

        replace('/packages');
        setImageErrorMessage(null);
        setImage(null);
        setSelectedHospitalName(null);
        setSelectedCategory(null);

        showSuccessSnackbar('The package has been created successfully!');
      } else {
        await myFetch({
          url: URIS.UPDATE_PACKAGES,
          payload: { ...submittedValues, package_id: packageId },
          loadingMessage: 'Updating the package information',
        });

        replace('/packages');

        showSuccessSnackbar('The package has been updated successfully!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)} spellCheck="false">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Dropzone
                accept=".jpg,.jpeg,.png"
                maxFiles={1}
                maxSize={4.5 * 1024 * 1024}
                multiple={false}
                onDrop={onDrop}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => (
                  <ButtonBase
                    classes={{ root: classes.ImageButton }}
                    style={{
                      ...(isDragActive ? activeStyle : {}),
                      ...(isDragReject || !!imageErrorMessage
                        ? rejectStyle
                        : {}),
                      ...(isDragAccept || !!image ? acceptStyle : {}),
                    }}
                    {...getRootProps()}
                  >
                    <input style={{ display: 'none' }} {...getInputProps()} />

                    {image || editInfo?.package_logo ? (
                      <img
                        src={image || editInfo?.package_logo}
                        alt={`${watch('package_name') || 'Package Pic'}`}
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <CameraAltIcon />
                    )}
                  </ButtonBase>
                )}
              </Dropzone>
              <HelperText marginLeftBuffer={0} error={!!imageErrorMessage}>
                {imageErrorMessage}
              </HelperText>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldController
              name="package_name"
              textFieldProps={{
                autoFocus: true,
                placeholder: 'Package Name*',
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FieldController name="parent_category">
              {({ field, isError, touched, helperText }) => (
                <AsyncPopperAutoComplete
                  name={field.name}
                  ref={field.ref}
                  onBlur={field.onBlur}
                  placeholderComponent="Category*"
                  contentComponent={(selectedCategory || '')
                    .split(' ')
                    .map(
                      (item) =>
                        `${item.slice(0, 1).toUpperCase()}${item.slice(1)}`,
                    )
                    .join(' ')}
                  onChange={(newValueDisp, newValue) => {
                    setSelectedCategory(newValueDisp);
                    field.onChange(newValue?.id || '');
                  }}
                  buttonWidthTruncate={90}
                  onPopperClose={field.onBlur}
                  error={isError}
                  touched={isError || touched}
                  helperText={helperText}
                  noOptionsText="No options available"
                  suggestionText="Please select a category"
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <CategoryIcon color="secondary" />
                      <Box width={theme.typography.pxToRem(12)} />
                      <span
                        style={{
                          color: selected
                            ? theme.palette.secondary.main
                            : '#000',
                        }}
                        className={classes.AutoCompleteMenuItemLabel}
                      >
                        {option.name}
                      </span>
                    </React.Fragment>
                  )}
                  getOptionLabel={getName}
                  getOptionSelected={(option, value) =>
                    option?.id === value?.id
                  }
                  onTypeLoad={fetchCategoryList}
                  disablePortal
                />
              )}
            </FieldController>
          </Grid>

          <Grid item xs={12}>
            <FieldController name="description">
              {({ field, isError, touched, helperText }) => (
                <RichTextEditor
                  placeholder="Description*"
                  {...field}
                  error={isError}
                  touched={touched}
                  helperText={helperText}
                />
              )}
            </FieldController>
          </Grid>

          <Grid item xs={12} md={6}>
            <FieldController name="hospital_cost">
              {({ field, isError, touched, helperText }) => (
                <TextField
                  placeholder="Cost Price*"
                  {...field}
                  error={isError}
                  touched={touched}
                  helperText={
                    helperText === 'Please enter a valid price' &&
                    field.value === ''
                      ? 'Cost Price is required'
                      : helperText
                  }
                  InputProps={{
                    startAdornment:
                      !isError &&
                      (field.value || '').length > 0 &&
                      !isNaN(parseFloat(field.value)) ? (
                        <IndianRupee
                          style={{
                            fontSize: theme.typography.pxToRem(14),
                            marginLeft: theme.typography.pxToRem(6),
                            marginRight: theme.typography.pxToRem(4),
                          }}
                        />
                      ) : null,
                  }}
                />
              )}
            </FieldController>
          </Grid>

          <Grid item xs={12} md={6}>
            <FieldController name="selling_cost">
              {({ field, isError, touched, helperText }) => (
                <TextField
                  placeholder="Selling Price*"
                  {...field}
                  error={isError}
                  touched={touched}
                  helperText={
                    helperText === 'Please enter a valid price' &&
                    field.value === ''
                      ? 'Selling Price is required'
                      : helperText
                  }
                  InputProps={{
                    startAdornment:
                      !isError &&
                      (field.value || '').length > 0 &&
                      !isNaN(parseFloat(field.value)) ? (
                        <IndianRupee
                          style={{
                            fontSize: theme.typography.pxToRem(14),
                            marginLeft: theme.typography.pxToRem(6),
                            marginRight: theme.typography.pxToRem(4),
                          }}
                        />
                      ) : null,
                  }}
                />
              )}
            </FieldController>
          </Grid>

          <Grid item xs={12} md={6}>
            <FieldController name="discounts">
              {({ field, isError, touched, helperText }) => (
                <TextField
                  placeholder="Discount Percentage*"
                  {...field}
                  error={isError}
                  touched={touched}
                  helperText={
                    helperText === 'Please enter a valid Discount Percentage' &&
                    field.value === ''
                      ? 'Discount Percentage is required'
                      : helperText
                  }
                  InputProps={{
                    classes: {
                      adornedEnd: classes.AdornEndDiscPercentage,
                    },
                  }}
                  renderEndAdornment={({ errorAndSuccessComponents }) => (
                    <InputAdornment position="end">
                      {field.value && !isNaN(parseInt(field.value)) ? (
                        <span style={{ marginRight: 6 }}>%</span>
                      ) : (
                        <Fragment />
                      )}
                      {errorAndSuccessComponents}
                    </InputAdornment>
                  )}
                />
              )}
            </FieldController>
          </Grid>

          <Grid item xs={12} md={6}>
            <FieldController name="hospital_id">
              {({ field, isError, touched, helperText }) => (
                <AsyncPopperAutoComplete
                  name={field.name}
                  ref={field.ref}
                  onBlur={field.onBlur}
                  placeholderComponent="Hospital Name*"
                  contentComponent={(selectedHospitalName || '')
                    .split(' ')
                    .map(
                      (item) =>
                        `${item.slice(0, 1).toUpperCase()}${item.slice(1)}`,
                    )
                    .join(' ')}
                  onChange={(newValueDisp, newValue) => {
                    setSelectedHospitalName(newValueDisp || '');
                    field.onChange(newValue?.hospital_id || '');
                  }}
                  buttonWidthTruncate={90}
                  onPopperClose={field.onBlur}
                  error={isError}
                  touched={isError || touched}
                  helperText={helperText}
                  noOptionsText="No options available"
                  suggestionText="Please select a hospital"
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <LocalHospitalIcon color="secondary" />
                      <Box width={theme.typography.pxToRem(12)} />
                      <span
                        style={{
                          color: selected
                            ? theme.palette.secondary.main
                            : '#000',
                        }}
                        className={classes.AutoCompleteMenuItemLabel}
                      >
                        {option.hospital_name}
                      </span>
                    </React.Fragment>
                  )}
                  getOptionLabel={getHospitalName}
                  getOptionSelected={(option, value) =>
                    option?.hospital_id === value?.hospital_id
                  }
                  onTypeLoad={fetchHospitalList}
                  disablePortal
                />
              )}
            </FieldController>
          </Grid>

          <Grid item xs={12} md={6}>
            <FieldController name="region">
              {({ field, isError, touched, helperText }) => (
                <PopperAutocomplete
                  name={field.name}
                  ref={field.ref}
                  onBlur={field.onBlur}
                  placeholderComponent="Region*"
                  contentComponent={(field.value || '')
                    .split(' ')
                    .map(
                      (item) =>
                        `${item.slice(0, 1).toUpperCase()}${item.slice(1)}`,
                    )
                    .join(' ')}
                  onChange={(newValueDisp, newValue) => {
                    field.onChange((newValueDisp || '').toLowerCase());
                  }}
                  buttonWidthTruncate={90}
                  onPopperClose={field.onBlur}
                  error={isError}
                  touched={isError || touched}
                  helperText={helperText}
                  noOptionsText="No options available"
                  suggestionText="Please select a region"
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <PinDropIcon color="secondary" />
                      <Box width={theme.typography.pxToRem(12)} />
                      <span
                        style={{
                          color: selected
                            ? theme.palette.secondary.main
                            : '#000',
                        }}
                        className={classes.AutoCompleteMenuItemLabel}
                      >
                        {option.name}
                      </span>
                    </React.Fragment>
                  )}
                  options={indianUpdatedStateList.sort(
                    (a, b) =>
                      -b.name.slice(0, 1).localeCompare(a.name.slice(0, 1)),
                  )}
                  getOptionLabel={getName}
                  getOptionSelected={(option, value) =>
                    option?.id === value?.id
                  }
                  disablePortal
                />
              )}
            </FieldController>
          </Grid>

          <Grid item xs={12} md={6}>
            <FieldController name="validity">
              {({ field, isError, touched, helperText }) => (
                <TextField
                  placeholder="Validity* (in days)"
                  {...field}
                  error={isError}
                  touched={touched}
                  helperText={
                    helperText === 'Please enter a valid no. of days' &&
                    field.value === ''
                      ? 'Validity is required'
                      : helperText
                  }
                  InputProps={{
                    classes: {
                      adornedEnd: classes.AdornEndValidity,
                    },
                  }}
                  renderEndAdornment={({ errorAndSuccessComponents }) => (
                    <InputAdornment position="end">
                      {field.value && !isNaN(parseInt(field.value)) && (
                        <span style={{ marginRight: 6 }}>days</span>
                      )}
                      {errorAndSuccessComponents}
                    </InputAdornment>
                  )}
                />
              )}
            </FieldController>
          </Grid>
          <Grid item xs={12}>
            <Alert
              severity="info"
              classes={{
                root: classes.AlertStyles,
              }}
            >
              <AlertTitle>Hint</AlertTitle>
              <div
                style={{
                  maxWidth: theme.typography.pxToRem(700),
                  width: '100%',
                }}
              >
                Use <strong>comma seperators &lsquo;,&rsquo;</strong> to add{' '}
                <strong>multiple tags</strong>!
              </div>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <FieldController name="tags">
              {({ field, isError, touched, helperText }) => (
                <div>
                  <TextField
                    placeholder="Tags"
                    {...field}
                    error={isError}
                    touched={touched && (field?.value || '').length > 0}
                    helperText={helperText}
                  />

                  <Alert
                    severity={
                      field.value === ''
                        ? 'warning'
                        : isError
                        ? 'error'
                        : 'success'
                    }
                    icon={
                      field.value !== '' && !isError ? <LabelIcon /> : <></>
                    }
                    variant="outlined"
                    style={{
                      marginTop: theme.typography.pxToRem(40),
                      borderStyle: 'dashed',
                    }}
                    classes={{
                      root: classes.AlertStyles,
                    }}
                  >
                    {field.value !== '' && !isError && (
                      <AlertTitle>Tags Added</AlertTitle>
                    )}
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      {field.value === '' ? (
                        <Fragment>
                          No <strong>tags</strong> have been{' '}
                          <strong>added</strong> yet!
                        </Fragment>
                      ) : isError ? (
                        <Fragment>
                          There is an <strong>error</strong> in the{' '}
                          <strong>Tags</strong> field!
                        </Fragment>
                      ) : (
                        <ul className={classes.TagList}>
                          {tagExtractor(field.value).map((item, index) => (
                            <li key={`${item}${index}`}>
                              <Chip
                                style={{
                                  paddingRight: theme.typography.pxToRem(10),
                                  fontWeight: 500,
                                }}
                                avatar={
                                  <Avatar
                                    style={{ backgroundColor: 'transparent' }}
                                  >
                                    <LabelIcon
                                      style={{
                                        fontSize: '140%',
                                        color: theme.palette.secondary.main,
                                      }}
                                    />
                                  </Avatar>
                                }
                                label={item}
                              />
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </Alert>
                </div>
              )}
            </FieldController>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <FieldController name="is_physical">
                {({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        name={field.name}
                        inputRef={field.ref}
                        checked={field.value === '0'}
                        color="default"
                        classes={{
                          track: classes.TrackActive,
                          thumb: classes.Thumb,
                        }}
                        onChange={() =>
                          field.onChange(field.value === '0' ? '1' : '0')
                        }
                      />
                    }
                    label="Is it a Shippable Package?"
                  />
                )}
              </FieldController>
            </Grid>
            <Grid item xs={12}>
              <FieldController name="sponsored">
                {({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        name={field.name}
                        inputRef={field.ref}
                        checked={field.value === '1'}
                        color="default"
                        classes={{
                          track: classes.TrackActive,
                          thumb: classes.Thumb,
                        }}
                        onChange={() =>
                          field.onChange(field.value === '0' ? '1' : '0')
                        }
                      />
                    }
                    label="Is this package Sponsored?"
                  />
                )}
              </FieldController>
            </Grid>
          </Grid>

          <Box width="100%" height={theme.typography.pxToRem(16)} />

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.AddPackageBtn}
              type="submit"
            >
              {editInfo ? 'Edit Package' : 'Add Package'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AddPackagesForm;

const validationSchema = Yup.object().shape({
  package_name: Yup.string()
    .required('Package Name is required')
    .matches(DOUBLE_WHITESPACES, 'Please remove extra white spaces')
    .max(40, 'Max 40 characters allowed'),

  parent_category: Yup.string().required('Category is required'),

  description: Yup.string().required('Description is required'),

  hospital_cost: Yup.number()
    .typeError('Please enter a valid price')
    .positive('Only positive numbers are allowed')
    .required('Cost Price is required'),

  selling_cost: Yup.number()
    .typeError('Please enter a valid price')
    .positive('Only positive numbers are allowed')
    .moreThan(
      Yup.ref('hospital_cost'),
      "Please enter a no. '>' than or '=' to Cost Price",
    ),

  discounts: Yup.number()
    .typeError('Please enter a valid Discount Percentage')
    .moreThan(-1, 'Only positive numbers are allowed'),

  hospital_id: Yup.string().required('Hospital Name is required'),

  region: Yup.string().required('Region is required'),

  validity: Yup.number()
    .typeError('Please enter a valid no. of days')
    .positive('Only positive numbers are allowed'),

  tags: Yup.string().matches(VALIDATE_TAGS, {
    message: 'Only alphabets, digits and commas are allowed',
    excludeEmptyString: true,
  }),

  is_physical: Yup.string().required(),

  sponsored: Yup.string().required(),
});

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      overflow: 'hidden',
    },

    '.root': {
      overflow: 'hidden',
    },
  },

  ImageButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.typography.pxToRem(18),
    overflow: 'hidden',
    height: theme.typography.pxToRem(200),
    width: theme.typography.pxToRem(250),
    border: `2px dashed ${colors.grey[600]}`,

    '&:focus': {
      borderColor: theme.palette.secondary.main,
    },
  },
  AddPackageBtn: {
    padding: theme.spacing(
      theme.typography.pxToRem(8),
      theme.typography.pxToRem(32),
    ),
  },
  AlertStyles: {
    padding: theme.spacing(
      theme.typography.pxToRem(8),
      theme.typography.pxToRem(24),
    ),
  },
  AdornEndValidity: {
    '& .MuiInputBase-inputAdornedEnd': {
      padding: theme.spacing(
        0,
        theme.typography.pxToRem(90),
        0,
        theme.typography.pxToRem(20),
      ),
    },
  },
  AdornEndDiscPercentage: {
    '& .MuiInputBase-inputAdornedEnd': {
      padding: theme.spacing(
        0,
        theme.typography.pxToRem(65),
        0,
        theme.typography.pxToRem(20),
      ),
    },
  },
  TagList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',

    '& > li': {
      margin: 0,
      padding: 0,
      marginRight: theme.typography.pxToRem(8),
      marginTop: theme.typography.pxToRem(8),
    },
  },
  Thumb: {
    backgroundColor: `${colors.grey[300]} !important`,

    '.Mui-checked &': {
      backgroundColor: `${theme.palette.success.main} !important`,
    },
  },
  TrackActive: {
    '.Mui-checked + &': {
      backgroundColor: theme.palette.success.main,
    },
  },
}));
