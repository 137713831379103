import React from 'react';
import { alpha } from '@material-ui/core/styles';

const ScrollThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: alpha('#000', 0.8),
  };

  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

export default ScrollThumb;
