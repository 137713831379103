import React from 'react';
import { makeStyles, ButtonBase } from '@material-ui/core';

const PrimaryCard = ({
  disabled = true,
  cardColor = '#000',
  textColor = '#fff',
  value = <></>,
  title = <></>,
  classes = {
    root: '',
    cardValue: '',
    cardTitle: '',
  },
  autoResponsive = true,
  ...props
}) => {
  const baseCardClasses = useStyles();

  const { cardValue = '', cardTitle = '', ...propClasses } = classes;

  return (
    <ButtonBase
      {...props}
      disabled={disabled}
      classes={{
        ...propClasses,
        root: [
          baseCardClasses.CardRoot,
          autoResponsive ? baseCardClasses.CardRootResponsive : '',
          propClasses?.root || '',
        ].join(' '),
      }}
      style={{
        ...props.style,
        backgroundColor: props.style?.backgroundColor || cardColor,
        color: props.style?.color || textColor,
      }}
    >
      <div
        className={[
          baseCardClasses.CardValue,
          autoResponsive ? baseCardClasses.CardValueResponsive : '',
          cardValue,
        ].join(' ')}
      >
        {value}
      </div>
      <h4
        className={[
          baseCardClasses.CardTitle,
          autoResponsive ? baseCardClasses.CardTitleResponsive : '',
          cardTitle,
        ].join(' ')}
      >
        {title}
      </h4>
    </ButtonBase>
  );
};

export default PrimaryCard;

const useStyles = makeStyles((theme) => ({
  CardRoot: {
    fontFamily: 'inherit',
    borderRadius: theme.typography.pxToRem(30),
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    padding: theme.spacing(
      theme.typography.pxToRem(30),
      theme.typography.pxToRem(30),
      theme.typography.pxToRem(30),
      theme.typography.pxToRem(30),
    ),
  },
  CardRootResponsive: {
    '@media (max-width:780px)': {
      borderRadius: theme.typography.pxToRem(20),
    },
  },
  CardValue: {
    fontSize: theme.typography.pxToRem(60),
    fontWeight: 700,
    margin: 0,
    textAlign: 'right',
  },
  CardValueResponsive: {
    '@media (max-width:780px)': {
      fontSize: theme.typography.pxToRem(40),
    },
  },
  CardTitle: {
    fontWeight: '700',
    fontSize: theme.typography.pxToRem(40),
    margin: '0',
    lineHeight: theme.typography.pxToRem(40),
    textAlign: 'right',
  },
  CardTitleResponsive: {
    '@media (max-width:780px)': {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
}));
