import React, { forwardRef, Fragment } from 'react';
import {
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Tooltip,
  colors,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import env, { ACCESS } from '../../.env';
import useApiCall from '../../hooks/useApiCall';
import URIS from '../../api';
import useSnackbar from '../../hooks/useSnackbar';
import DialogLoaderSlide from '../../components/Loader/DialogLoaderSlide';

const PackageItem = ({
  package_name,
  region,
  validity,
  package_short_name,
  selling_credits,
  package_id,
  package_logo,
  hospital_credits,
  category,
  name,
  onDelete,
  loading = false,
  openConfirmationDialog,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesUp500p = useMediaQuery('(min-width: 500px)');
  const matchesUp580p = useMediaQuery('(min-width: 580px)');

  const { myFetch: removePackage, isLoading: removingPackage } = useApiCall();

  const { showSuccessSnackbar } = useSnackbar();

  const onClickDeleteIcon = async () => {
    if (typeof openConfirmationDialog !== 'function') {
      return;
    }

    openConfirmationDialog(null, {
      autoCloseWhenButtonClicked: false,
      autoCloseWhenBackdropClicked: true,
      minHeight: matchesUp580p
        ? theme.typography.pxToRem(190)
        : matchesUp500p
        ? theme.typography.pxToRem(200)
        : theme.typography.pxToRem(210),
      content: (
        <span style={{ lineHeight: 1.8 }}>
          Do you really want to delete the package{' '}
          <strong>{package_name}</strong> with the package id{' '}
          <strong>{package_id}</strong>?
        </span>
      ),
      slides: [
        <DialogLoaderSlide loadMessage="Please wait. We are removing your package!" />,
      ],
      onClickButtons: async (
        selected,
        closeDialog,
        updateSlides,
        blockBackdropClose,
      ) => {
        if (selected === 'confirm') {
          blockBackdropClose();
          updateSlides();
          try {
            await removePackage({
              url: URIS.REMOVE_PACKAGE,
              payload: {
                package_id,
              },
            });

            showSuccessSnackbar(
              `The package, ${package_name}, was removed successfully`,
            );

            if (onDelete) {
              onDelete();
            }
          } catch (error) {
            console.log(error);
          }

          closeDialog();
        } else {
          closeDialog();
        }
      },
    });
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        className={classes.PackageWrapper}
        spacing={3}
      >
        <Grid item xs={12} sm={4} md={3}>
          {loading ? (
            <Skeleton variant="rect" className={classes.PackageImage} />
          ) : (
            <img
              src={package_logo}
              alt={`${package_name} logo`}
              className={classes.PackageImage}
            />
          )}
        </Grid>

        <Grid item container xs={10} sm={6} md={8}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.PackageHeding}>
              {loading ? <Skeleton width="70%" /> : package_name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" style={{ color: colors.grey[600] }}>
              {loading ? (
                <Skeleton width="50%" />
              ) : (
                <Fragment>Package Id: {package_id}</Fragment>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="primary">
              {loading ? (
                <Skeleton width="30%" />
              ) : (
                (typeof category === 'string'
                  ? category
                  : typeof name === 'string'
                  ? name
                  : ''
                ).toUpperCase()
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {loading ? (
                <Skeleton width="40%" />
              ) : (
                <Fragment>Validity: {validity} days</Fragment>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ color: colors.grey[500], textTransform: 'capitalize' }}
            >
              {loading ? <Skeleton width="30%" /> : region}
            </Typography>
          </Grid>
          <Grid item container xs={12} className={classes.PriceSection}>
            <Grid item xs={6}>
              <Typography variant="body1">
                {loading ? (
                  <Skeleton width="50%" />
                ) : (
                  <Fragment>Hospital Credits: {hospital_credits}</Fragment>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {loading ? (
                  <Skeleton width="50%" />
                ) : (
                  <Fragment>Selling Credits: {selling_credits}</Fragment>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={2} sm={2} md={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {loading ? (
            <Skeleton variant="rect" width={24} height={120} />
          ) : (
            <Fragment>
              <Tooltip title="View Package">
                <IconButton
                  component={forwardRef((props, ref) => (
                    <a
                      href={`${env({
                        access: ACCESS.USER_PORTAL_URL,
                      })}/passes/${package_short_name}`}
                      rel="noreferrer"
                      target="_blank"
                      ref={ref}
                      {...props}
                    >
                      {props.children}
                    </a>
                  ))}
                >
                  <VisibilityIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Edit Package"
                component={forwardRef((props, ref) => (
                  <Link
                    to={`/edit-packages/${package_id}`}
                    innerRef={ref}
                    {...props}
                  />
                ))}
              >
                <IconButton>
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>
              {removingPackage ? (
                <span style={{ display: 'inline-block', marginTop: 10 }}>
                  <CircularProgress size={24} />
                </span>
              ) : (
                <Tooltip title="Delete Package">
                  <IconButton
                    onClick={onClickDeleteIcon}
                    disabled={removingPackage}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          )}
        </Grid>
      </Grid>
      <Divider />
    </div>
  );
};

export default PackageItem;

const useStyles = makeStyles((theme) => ({
  PackageWrapper: {
    width: '100%',
    padding: theme.spacing(theme.typography.pxToRem(16), 0),
    marginRight: 0,
    marginLeft: 0,
  },
  PackageHeding:{
    fontSize:24,
    fontWeight:'600',
    '@media (max-width:780px)': {
      fontSize: '20px',
    },
  },
  PackageImage: {
    display: 'block',
    height: theme.typography.pxToRem(200),
    width: '100%',
    objectFit: 'cover',
    borderRadius: theme.typography.pxToRem(16),
  },
  PriceSection: {
    padding: theme.spacing(theme.typography.pxToRem(8), 0),
  },
}));
