import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from '../axios';

const Initialize = ({ children }) => {
  const [init, setInit] = useState(false);

  const authToken = useSelector((state) => state.auth.authToken);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = authToken;

    if (authToken) {
      setInit(true);
    }
  }, [authToken]);

  return !isLoggedIn ? children : init ? children : null;
};

export default Initialize;
