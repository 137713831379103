import React from 'react';
import { Collapse, makeStyles } from '@material-ui/core';

const HelperText = ({
  showValidationDefault = true,
  error = false,
  children = '',
  helperTextClassName = '',
  errorHelperTextClassName = '',
  marginLeftBuffer = 2.5 * 8,
}) => {
  const classes = useStyles();

  const helperTextClasses = [classes.HelperTextDiv, helperTextClassName];

  if (showValidationDefault) {
    if (error) {
      helperTextClasses.push(classes.ErrorDiv);
      helperTextClasses.push(errorHelperTextClassName);
    }
  }

  return (
    <Collapse in={!!children}>
      <div
        className={helperTextClasses.join(' ')}
        style={{ marginLeft: marginLeftBuffer }}
      >
        {children}
      </div>
    </Collapse>
  );
};

export default HelperText;

const useStyles = makeStyles((theme) => ({
  HelperTextDiv: {
    fontSize: theme.typography.pxToRem(13),
    margin: theme.spacing(0.5, 0, 0, 0),
  },
  ErrorDiv: {
    color: theme.palette.error.main,
  },
}));
