import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

const PageHeading = ({
  children = <></>,
  classes = { head: '', container: '' },
  autoResponsive = true,
}) => {
  const componentClasses = useStyles();

  return (
    <Box
      className={[componentClasses.PageHeading, classes?.container || ''].join(
        ' ',
      )}
    >
      <h1
        className={[
          componentClasses.Head1,
          autoResponsive ? componentClasses.Head1Responsive : '',
          classes?.head || '',
        ].join(' ')}
      >
        {children}
      </h1>
    </Box>
  );
};

export default PageHeading;

const useStyles = makeStyles((theme) => ({
  PageHeading: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    color: '#000',
  },
  Head1: {
    fontSize: theme.typography.pxToRem(65),
    fontWeight: 300,
    margin: 0,
    lineHeight: 1,

    '& strong': {
      fontWeight: 700,
    },
  },
  Head1Responsive: {
    '@media (max-width:780px)': {
      fontSize: theme.typography.pxToRem(40),
    },
  },
}));
