import React from 'react';
import { Divider, Grid, makeStyles, Paper } from '@material-ui/core';

import InlineStyleControls from './InlineStyleControls';
import BlockStyleControls from './BlockStyleControls';
import EntityControls from './EntityControls';

const EditorControlsToolbar = ({
  editorState,
  toggleInlineStyle,
  toggleBlockType,
  toolbarFocused,
  editorFocused,
  onClickToolbarButtons,
  confirmLink,
  removeLink,
}) => {
  const classes = useStyles();

  const onClickToolbar = (e) => {
    e.stopPropagation();

    const {
      target: {
        type,
        dataset: {
          type: dataSetType,
          block: dataSetBlock,
          style: dataSetStyle,
        },
      },
    } = e;

    if (type === 'button' && dataSetType === 'richtext-control') {
      if (onClickToolbarButtons instanceof Function) {
        onClickToolbarButtons();

        if (dataSetBlock === 'false' && !editorFocused) {
          toggleInlineStyle(dataSetStyle);
        }
      }
    }
  };

  return (
    <div className={classes.Toolbar}>
      <Paper className={classes.Paper}>
        <div
          className={[
            classes.ControlsWrapper,
            !toolbarFocused ? classes.ControlsWrapperFocused : '',
          ].join(' ')}
          onClick={onClickToolbar}
        >
          <Grid container spacing={2} direction="row">
            <Grid item>
              <BlockStyleControls
                editorState={editorState}
                onToggle={toggleBlockType}
              />
            </Grid>
            <Grid item style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <InlineStyleControls
                editorState={editorState}
                onToggle={toggleInlineStyle}
              />
            </Grid>
            <Grid item style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              <EntityControls
                editorState={editorState}
                focusEditor={onClickToolbarButtons}
                confirmLink={confirmLink}
                removeLink={removeLink}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

export default EditorControlsToolbar;

const useStyles = makeStyles((theme) => ({
  Toolbar: {
    padding: theme.spacing(2, 0),
  },
  Paper: {
    padding: theme.spacing(1, 2),
    boxShadow: '0 0 6px 0 rgb(76 87 125 / 16%)',
  },
  ControlsWrapper: {
    opacity: 1,
    transition: 'opacity 200ms ease-in',
  },
  ControlsWrapperFocused: {
    opacity: 0.3,
  },
}));
