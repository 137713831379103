import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { SyncLoader } from 'react-spinners';

import Button from '../../components/Button';
import TextField from '../../components/TextField';
import FieldController from './FieldController';
import PhoneNumberField from '../../components/PhoneNumberField';
import {
  DOUBLE_WHITESPACES,
  PHONE_REGEX_WITHOUT_91,
  PASSWORD,
} from '../../utility/regex';
import PasswordErrorMessage from '../../components/PasswordErrorMessage';
import { fetchCompleteUserDetails } from '../../store/thunks/profile-detail-thunks';
import { profileDetailsActions } from '../../store/reducers/profile-details-slice';
import useApiCall from '../../hooks/useApiCall';
import NotFoundIcon from '../../icons/NotFound';
import URIS from '../../api';
import useSnackbar from '../../hooks/useSnackbar';
import { USER_DETAILS } from '../../global-constants';

const UpdateProfileForm = ({ userDetails = {}, resetForm = () => {} }) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch();

  const { myFetch, isLoading } = useApiCall();
  const { showSuccessSnackbar } = useSnackbar();

  const initialValuesRef = useRef({
    hospital_name: userDetails?.name || '',
    email: userDetails?.email || '',
    phone_no: userDetails?.phone_no || '',
    state: userDetails?.state || '',
    city: userDetails?.city || '',
    password: '',
    confirmPassword: '',
  });

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: initialValuesRef.current,
    resolver: yupResolver(
      validationSchema({
        initializedWithPhoneNumber: !!initialValuesRef.current.phone_no,
      }),
    ),
    shouldUnregister: true,
  });

  const onSubmit = async (values) => {
    const convertedValues = Object.entries(values).reduce(
      (prevValue, [key, value]) => {
        switch (key) {
          case 'confirmPassword':
            return prevValue;

          case 'password':
            return { ...prevValue, [key]: value };

          case 'dob':
            return {
              ...prevValue,
              [key]: dayjs(value).format('YYYY-MM-DD'),
            };

          default:
            return {
              ...prevValue,
              [key]: value.trim(),
            };
        }
      },
      {},
    );

    const submittedValues = {
      ...convertedValues,
      hospital_id: userDetails?.id,
      country: '101',
      username: convertedValues.email,
      picture: '',
      picture_name: '',
    };

    try {
      await myFetch({
        url: URIS.UPDATE_HOSPITAL_PROFILE,
        payload: submittedValues,
      });

      const { password, username, ...globalUpdatedValues } = submittedValues;

      globalUpdatedValues.user_name = username;

      const userDetailsSavedInLocalStorage = localStorage.getItem(USER_DETAILS)
        ? JSON.parse(localStorage.getItem(USER_DETAILS))
        : null;

      dispatch(profileDetailsActions.setUserDetails(globalUpdatedValues));

      localStorage.setItem(
        USER_DETAILS,
        JSON.stringify({
          ...userDetailsSavedInLocalStorage,
          ...globalUpdatedValues,
        }),
      );

      const updatedFormFields = {
        ...submittedValues,
        password: '',
        confirmPassword: '',
      };

      initialValuesRef.current = updatedFormFields;

      methods.reset(updatedFormFields);
      resetForm();

      showSuccessSnackbar('Your credentials has been updated successfully!');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FieldController
              name="hospital_name"
              textFieldProps={{
                autoFocus: true,
                placeholder: 'Hospital Name',
              }}
            />
          </Grid>
          {matchesUpMd && <Grid item xs={12} md={6}></Grid>}
          <Grid item xs={12} md={6}>
            <FieldController
              name="email"
              textFieldProps={{ placeholder: 'Email', type: 'email' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldController
              name="phone_no"
              optional={!initialValuesRef.current.phone_no}
            >
              {({
                field,
                isError,
                touched,
                helperText,
                optionalTextFieldProps,
              }) => (
                <PhoneNumberField
                  placeholder="Mobile Number"
                  {...field}
                  error={isError}
                  touched={touched}
                  helperText={helperText}
                  {...optionalTextFieldProps}
                />
              )}
            </FieldController>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldController
              name="state"
              textFieldProps={{ placeholder: 'State' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldController
              name="city"
              textFieldProps={{ placeholder: 'City' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldController name="password" optional>
              {({
                field,
                isError,
                touched,
                helperText,
                optionalTextFieldProps,
              }) => (
                <TextField
                  {...field}
                  placeholder="Password"
                  type="password"
                  error={isError}
                  touched={touched}
                  helperText={
                    (helperText =
                      'Invalid Password Format' && isError ? (
                        <div
                          style={{
                            display: 'grid',
                            gridAutoFlow: 'row',
                            gridRowGap: '5px',
                            rowGap: '5px',
                          }}
                        >
                          <div>Passwords should:</div>
                          {passwordHelperTextGenerator(field.value).map(
                            (item) => (
                              <PasswordErrorMessage
                                key={item.message}
                                condition={item.condition}
                              >
                                {item.message}
                              </PasswordErrorMessage>
                            ),
                          )}
                        </div>
                      ) : (
                        ''
                      ))
                  }
                  {...optionalTextFieldProps}
                />
              )}
            </FieldController>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldController
              name="confirmPassword"
              optional
              textFieldProps={{
                placeholder: 'Confirm Password',
                type: 'password',
              }}
            />
          </Grid>

          <Box width="100%" height={theme.typography.pxToRem(30)} />

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.UpdateProfileBtn}
              type="submit"
              disabled={
                !(
                  methods.formState.isDirty &&
                  Object.entries(methods.formState.dirtyFields).length > 0
                ) || isLoading
              }
            >
              {isLoading ? (
                <SyncLoader color={theme.palette.primary.main} />
              ) : (
                'Update Profile'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

const UpdateFormInitLoad = () => {
  const theme = useTheme();

  const profileDetails = useSelector((state) => state.profileDetails);
  const dispatch = useDispatch();

  const userDetailsRef = useRef(null);

  const [resetDone, setResetDone] = useState(true);

  useEffect(() => {
    if (!resetDone) {
      setResetDone(true);
    }
  }, [resetDone]);

  useEffect(() => {
    userDetailsRef.current = profileDetails.userDetails;
  }, [profileDetails]);

  useEffect(() => {
    if (userDetailsRef.current?.date_of_birth === undefined) {
      dispatch(fetchCompleteUserDetails({}));
    } else {
      dispatch(profileDetailsActions.setFetchingUserDetails(false));
    }
  }, [dispatch]);

  return profileDetails.fetchingUserDetails.loading ? (
    <Box
      height={theme.typography.pxToRem(452)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ marginBottom: theme.typography.pxToRem(20) }}>
        <SyncLoader color={theme.palette.primary.main} />
      </div>
      Fetching your details...
    </Box>
  ) : profileDetails.fetchingUserDetails.errorMessage ? (
    <Box
      height={theme.typography.pxToRem(452)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.error.main,
      }}
    >
      <div
        style={{
          marginBottom: theme.typography.pxToRem(-40),
          fontSize: theme.typography.pxToRem(180),
        }}
      >
        <NotFoundIcon htmlColor={theme.palette.error.main} fontSize="inherit" />
      </div>
      {profileDetails.fetchingUserDetails.errorMessage}
    </Box>
  ) : resetDone ? (
    <UpdateProfileForm
      userDetails={profileDetails.userDetails || {}}
      resetForm={setResetDone.bind(null, false)}
    />
  ) : null;
};

export default UpdateFormInitLoad;

function passwordHelperTextGenerator(string) {
  string = string || '';

  return [
    { condition: string.length >= 8, message: 'Be minimum 8 characters long' },
    {
      condition: /[A-Z]/.test(string),
      message: 'Have atleast 1 uppercase character',
    },
    {
      condition: /[a-z]/.test(string),
      message: 'Have atleast 1 lowercase character',
    },
    { condition: /[0-9]/.test(string), message: 'Have atleast 1 digit' },
    {
      condition: /[#?.!@$%/^&*-]/.test(string),
      message: 'Have atleast 1 special character',
    },
  ];
}

function validationSchema({ initializedWithPhoneNumber = false }) {
  return Yup.object().shape({
    hospital_name: Yup.string()
      .trim()
      .required('Hospital name is required')
      .matches(DOUBLE_WHITESPACES, 'Please remove extra white spaces')
      .max(200, 'Max 200 characters allowed'),

    email: Yup.string()
      .trim()
      .required('Email Address is required')
      .email('Invalid Email Address'),

    phone_no: initializedWithPhoneNumber
      ? Yup.string()
          .trim()
          .required('Mobile number is required')
          .matches(PHONE_REGEX_WITHOUT_91, {
            message: 'Invalid Phone Number',
            excludeEmptyString: true,
          })
      : Yup.string().trim().matches(PHONE_REGEX_WITHOUT_91, {
          message: 'Invalid Phone Number',
          excludeEmptyString: true,
        }),

    state: Yup.string()
      .required('State is required')
      .matches(DOUBLE_WHITESPACES, 'Please remove extra white spaces')
      .max(100, 'Max 100 characters allowed'),

    city: Yup.string()
      .required('City is required')
      .matches(DOUBLE_WHITESPACES, 'Please remove extra white spaces')
      .max(100, 'Max 100 characters allowed'),

    password: Yup.string().matches(PASSWORD, {
      message: 'Invalid Password Format',
      excludeEmptyString: true,
    }),

    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match',
    ),
  });
}

const useStyles = makeStyles(() => ({
  UpdateProfileBtn: {
    width: '190px',
  },
}));
