import React from 'react';
import { useSelector } from 'react-redux';

import SnackBarAlert from '../components/SnackBarAlert';
import useSnackbar from '../hooks/useSnackbar';

const SnackBarHOC = ({ children }) => {
  const snackBarState = useSelector((state) => state.ui.snackBarState);

  const { closeSnackbar } = useSnackbar();

  return (
    <React.Fragment>
      <SnackBarAlert
        message={snackBarState.message}
        type={snackBarState.type}
        duration={snackBarState.duration}
        closeSnackBar={closeSnackbar}
      />
      {children}
    </React.Fragment>
  );
};

export default SnackBarHOC;
