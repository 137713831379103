import React from 'react';
import { Box, InputAdornment, makeStyles } from '@material-ui/core';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

import Button from '../../components/Button';
import TextField from '../../components/TextField';
import { loginUsingEmailAndPassword } from '../../store/thunks/auth-thunks';

const LoginForm = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const initialValues = {
    username: '',
    password: '',
  };

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values) => {
    const submittedValues = {
      ...values,
      username: values.username.trim(),
    };

    dispatch(loginUsingEmailAndPassword(submittedValues));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <div className={classes.InputContainer}>
          <Controller
            name="username"
            render={({ field, fieldState: { error, isTouched } }) => (
              <TextField
                type="email"
                autoFocus
                {...field}
                error={!!error?.message}
                touched={isTouched}
                helperText={error?.message}
                showValidationDefault={!!error?.message}
                renderEndAdornment={
                  !!error?.message
                    ? ({ errorAndSuccessComponents }) => (
                        <InputAdornment position="end">
                          {errorAndSuccessComponents}
                        </InputAdornment>
                      )
                    : () => null
                }
                placeholder="Email Address"
              />
            )}
          />
        </div>
        <div className={classes.InputContainer}>
          <Controller
            name="password"
            render={({ field, fieldState: { error, isTouched } }) => (
              <TextField
                type="password"
                {...field}
                error={!!error?.message}
                touched={isTouched}
                helperText={error?.message}
                showValidationDefault={!!error?.message}
                renderEndAdornment={
                  !!error?.message
                    ? ({ errorAndSuccessComponents }) => (
                        <InputAdornment position="end">
                          {errorAndSuccessComponents}
                        </InputAdornment>
                      )
                    : () => null
                }
                placeholder="Password"
              />
            )}
          />
        </div>
        <div className={classes.ForgetPasswordContainer}>
          <Button
            variant="text"
            backgroundTransparent
            disableElevation
            disableFocusRipple
            disableRipple
            classes={{ text: classes.ForgetPasswordButton }}
          >
            Forgot Password ?
          </Button>
        </div>
        <Box className={classes.Btncol}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.LoginBtn}
          >
            Login
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

export default LoginForm;

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .required('Email Address is required')
    .email('Invalid Email Address'),
  password: Yup.string().required('Password is required'),
  // .min(8, 'Password should be atleast 8 characters long'),
});

const useStyles = makeStyles(() => ({
  Btncol: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  LoginBtn: {
    width: '190px',
    '@media (max-width:780px)': {
      width: '170px',
    },
  },
  InputContainer: {
    width: '100%',
    marginBottom: '30px',
  },
  ForgetPasswordContainer: {
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '30px',
    '@media (max-width:780px)': {
      fontSize: '16px',
    },
  },
  ForgetPasswordButton: {
    color: '#878787',
    textTransform: 'capitalize',
    fontSize: 'inherit',
    fontWeight: 'normal',
  },
}));
