import React from 'react';
import { useTheme, Grid } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CachedIcon from '@material-ui/icons/Cached';

const SelectItems = ({ color, Icon, label, selected = 0, style }) => {
  const theme = useTheme();

  const options = [
    {},
    {
      id: 'pending',
      label: 'Pending',
      Icon: ErrorOutlineIcon,
      color: theme.palette.error.main,
    },
    {
      id: 'in_progress',
      label: 'In-progress',
      Icon: CachedIcon,
      color: theme.palette.secondary.main,
    },
    {
      id: 'completed',
      label: 'Done',
      Icon: CheckCircleOutlineIcon,
      color: theme.palette.success.main,
    },
  ];

  let selectedOption = 0;

  if (!!selected) {
    if (isNaN(parseInt(selected))) {
      selectedOption = options.findIndex((item) => selected === item?.id);
      selectedOption = selectedOption !== -1 ? selectedOption : 0;
    } else {
      selectedOption = parseInt(selected) > 3 ? 0 : parseInt(selected);
    }
  }

  const {
    color: selectedColor,
    Icon: SelectedIcon,
    label: selectedLabel,
  } = options[selectedOption];

  Icon = SelectedIcon || Icon;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        color: selectedColor || color,
        lineHeight: 1,
        ...style,
      }}
    >
      <span style={{ marginRight: theme.typography.pxToRem(4) }}>
        <Icon color="inherit" />
      </span>
      <span>{selectedLabel || label}</span>
    </Grid>
  );
};

export default SelectItems;
