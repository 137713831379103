import React from 'react';
import { Scrollbars as ScroollBars } from 'react-custom-scrollbars-2';

import ScrollThumb from './ScrollThumb';

const Scrollbars = (props) => (
  <ScroollBars
    renderThumbHorizontal={ScrollThumb}
    renderThumbVertical={ScrollThumb}
    autoHide
    autoHideTimeout={500}
    autoHideDuration={200}
    {...props}
  />
);

export default Scrollbars;
