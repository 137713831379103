import React from 'react';
import {
  CircularProgress,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';

import TextField from '../TextField';

const Autocomplete = React.forwardRef(
  (
    {
      error = false,
      touched = false,
      loading = false,
      textFieldProps = {},
      renderEndAdornment = (
        {
          circularProgress,
          errorAndSuccessComponents,
          endAdornmentIcons,
          // closeIcon,
          // dropDownIcon,
        },
        { loading, error, touched },
      ) => (
        <InputAdornment position="end">
          {loading ? circularProgress : null}
          {errorAndSuccessComponents}
          {/* {closeIcon}
          {dropDownIcon} */}
        </InputAdornment>
      ),
      ...props
    },
    ref,
  ) => {
    const { classes: autoCompleteClasses = {} } = props;

    const classes = useStyles();

    return (
      <MuiAutocomplete
        {...props}
        classes={{
          ...autoCompleteClasses,
          root: [
            classes.AutocompleteRoot,
            autoCompleteClasses?.root || '',
          ].join(' '),
        }}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            ref={ref}
            error={error}
            touched={touched}
            onChange={(e) => {
              if (textFieldProps.onChange) {
                textFieldProps.onChange(e);
              }
            }}
            renderEndAdornment={({ errorAndSuccessComponents }) => {
              const circularProgress = (
                <span style={{ display: 'inline-block' }}>
                  <CircularProgress color="inherit" size={20} />
                </span>
              );

              return renderEndAdornment(
                {
                  circularProgress,
                  errorAndSuccessComponents,
                  endAdornmentIcons: params.InputProps.endAdornment,
                  // closeIcon: params.InputProps.endAdornment.props.children[0],
                  // dropDownIcon:
                  //   params.InputProps.endAdornment.props.children[1],
                },
                { loading, error, touched },
              );
            }}
          />
        )}
      />
    );
  },
);

export default Autocomplete;

const useStyles = makeStyles((theme) => ({
  AutocompleteRoot: {
    '& .MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: '0 5px 0 0',
    },

    '& .MuiTextField-root .MuiInputBase-root .MuiAutocomplete-input': {
      padding: '0 20px',
    },
  },
}));
