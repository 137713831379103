import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const NavItem = ({ to = '', linkText = '', component = null }) => {
  const classes = useStyles();

  return (
    <li className={classes.RightNavItem}>
      {component || (
        <NavLink
          to={to}
          exact
          className={classes.NavLinkStyle}
          activeClassName={classes.NavLinkActiveStyle}
        >
          {linkText}
        </NavLink>
      )}
    </li>
  );
};

export default NavItem;

const useStyles = makeStyles((theme) => ({
  RightNavItem: {
    color: '#000',
  },
  NavLinkStyle: {
    color: '#000',
    textDecoration: 'none',
  },
  NavLinkActiveStyle: {
    fontWeight: 700,
    color: theme.palette.secondary.main,
    pointerEvents: 'none',
    cursor: 'default',
  },
}));
