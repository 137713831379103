import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LoginForm from '../containers/LoginForm';
import Page from '../hoc/Page';

import HeaderImg from '../images/happy-indian-couple-spending-time-together.png';
import star from '../images/star.svg';
import Icon1 from '../images/credit.svg';
import Icon2 from '../images/book.svg';
import Icon3 from '../images/Earn.png';

const Login = () => {
  const classes = useStyles();
  return (
    <Page>
      <Box className={classes.PageContent}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <h2>Earn Credits When You Book A Healthcare Service</h2>
            <Box className={classes.HeaderImage}>
              <img
                src={HeaderImg}
                alt="Indian Couple Spending time together!"
              />
              <Box className={classes.CreditBox}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <img src={star} alt="star" />
                </Box>
                <h3>You’ve Earned</h3>
                <h4> 50 Credits</h4>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box className={classes.CreditForm}>
              <h3 className={classes.CreditFormHeading}>Earn Credits</h3>
              <LoginForm />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.Howitwork}>
        <Box className={classes.PageContent}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <img src={Icon1} alt="icon 1" />
              <h4>Buy or Earn Credits</h4>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img src={Icon2} alt="icon 2" />
              <h4>Book A Healthcare Service</h4>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img src={Icon3} alt="icon 3" />
              <h4>Earn Back Credits</h4>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

export default Login;

const useStyles = makeStyles(() => ({
  PageContent: {
    width: '90%',
    margin: '90px auto 50px auto',
    '& h2': {
      fontSize: '50px',
      fontWeight: '700',
      '@media (max-width:780px)': {
        fontSize: '30px',
      },
    },
  },
  CreditBox: {
    width: '269px',
    height: '189px',
    padding: '0 20px 20px',
    borderRadius: '30px',
    boxShadow: '0px 0px 12px 8px rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    left: '20px',
    bottom: '30px',
    background: '#fff',
    zIndex: '99',
    '@media (max-width:780px)': {
      width: '175px',
      height: '135px',
      borderRadius: '20px',
    },

    '& h3': {
      fontSize: '30px',
      marginBottom: '0px',
      marginTop: '20px',
      '@media (max-width:780px)': {
        fontSize: '20px',
      },
    },

    '& h4': {
      fontSize: '42px',
      margin: '0px',
      '@media (max-width:780px)': {
        fontSize: '30px',
      },
    },

    '& img': {
      width: '70px !important',
      height: '70px',
      marginTop: '-15px',
      '@media (max-width:780px)': {
        width: '50px !important',
        height: '50px',
        marginTop: '-10px',
      },
    },
  },
  CreditForm: {
    padding: '140px 40px 0',
    '@media (max-width:780px)': {
      padding: '0',
    },
  },
  CreditFormHeading: {
    fontSize: '32px',
    fontWeight: '700',
    '@media (max-width:780px)': {
      fontSize: '24px',
    },
  },
  HeaderImage: {
    width: '100%',
    borderRadius: '20px',
    overflow: 'hidden',
    marginTop: '30px',
    position: 'relative',
    '& img': {
      width: '100%',
    },
  },
  Howitwork: {
    width: '100%',
    background: 'linear-gradient(180deg, #302F2F 0%, #000000 100%)',
    textAlign: 'center',
    padding: '30px 0',

    '& h4': {
      color: '#fff',
      fontSize: '28px',
      fontWeight: '700',
      marginBottom: '0px',
      textAlign: 'center',
      '@media (max-width:780px)': {
        fontSize: '20px',
      },
    },
  },
}));
