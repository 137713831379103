import React from 'react';
import { SvgIcon } from '@material-ui/core';

const IndianRupee = (props) => (
  <SvgIcon viewBox="0 0 447.185 447.185" {...props}>
    <defs />
    <path d="M358.204 96.283h-33.437c-2.211-19.379-8.961-37.519-19.672-51.56h53.108c12.721 0 23.022-9.499 23.022-22.216 0-12.723-10.302-22.484-23.022-22.484H178.118c-.659 0-1.294-.023-1.971-.023-.438 0-.877.023-1.315.023H88.981c-12.72 0-23.022 9.768-23.022 22.484s10.302 22.216 23.022 22.216h102.097c32.243 2.347 66.017 14.821 74.913 51.56H88.981c-12.72 0-23.022 10.309-23.022 23.031 0 12.717 10.302 23.031 23.022 23.031h174.716c-10.87 29.034-40.728 46.742-82.225 46.742h-72.62c-12.401 0-22.455 10.054-22.455 22.455 0 12.404 10.054 22.458 22.455 22.458h26.382c.109.012.207.065.316.065h41.665c45.268 1.72 65.402 21.35 76.946 75.055 9.032 39.892 15.682 65.875 20.912 81.438 3.919 14.398 11.674 36.091 25.127 49.048a27.065 27.065 0 0018.808 7.577c7.117 0 14.233-2.784 19.559-8.322 9.76-10.144 9.937-25.842.993-36.334-.041-.124-.023-.26-.088-.384-8.258-15.32-18.247-56.412-30.435-108.533-9.688-42.381-27.787-68.778-55.213-80.499 34.437-13.22 58.127-38.506 67.412-70.772h36.966c12.721 0 23.022-10.314 23.022-23.031s-10.299-23.025-23.02-23.025z" />
  </SvgIcon>
);

export default IndianRupee;
