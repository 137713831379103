import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import StyleButton from './StyleButton';

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  'divider',
  {
    label: 'UL',
    style: 'unordered-list-item',
    children: <FormatListBulletedIcon />,
  },
  {
    label: 'OL',
    style: 'ordered-list-item',
    children: <FormatListNumberedIcon />,
  },
];

const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <Grid container direction="row">
      {BLOCK_TYPES.map((type) =>
        type === 'divider' ? (
          <Divider
            key={type}
            orientation="vertical"
            flexItem
            style={{ margin: '0 8px' }}
          />
        ) : (
          <Grid item key={type.label}>
            <StyleButton
              active={type.style === blockType}
              label={type.label}
              onToggle={onToggle}
              style={type.style}
              children={type.children}
              block
            />
          </Grid>
        ),
      )}
    </Grid>
  );
};

export default BlockStyleControls;

// #757575
