import React from 'react';
import { Controller } from 'react-hook-form';

import TextField from '../../components/TextField';

const FieldController = ({
  name = '',
  textFieldProps = {},
  children = () => null,
  ...props
}) => {
  return (
    <Controller
      name={name}
      render={({
        field,
        fieldState: { error, isTouched },
        fieldState,
        formState,
      }) => {
        const component = !!children
          ? children({
              field,
              fieldState,
              formState,
              isError: !!error?.message,
              touched: isTouched,
              helperText: error?.message,
            })
          : null;

        return React.isValidElement(component) ? (
          component
        ) : (
          <TextField
            {...field}
            error={!!error?.message}
            touched={isTouched}
            helperText={error?.message}
            {...textFieldProps}
          />
        );
      }}
      {...props}
    />
  );
};

export default FieldController;
