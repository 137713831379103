import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Grid,
  InputAdornment,
  LinearProgress,
} from '@material-ui/core';
import { alpha, lighten } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import TextField from '../../components/TextField';

const SearchStrip = ({
  onInputChange,
  searching = false,
  searchInputPlaceholderText = 'Search Packages',
}) => {
  const classes = useStyles();

  const [input, setInput] = useState('');

  return (
    <Box className={classes.Container}>
      <form>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <TextField
              type="search"
              placeholder={searchInputPlaceholderText}
              renderEndAdornment={() => (
                <InputAdornment position="end">
                  <SearchIcon color="inherit" />
                </InputAdornment>
              )}
              value={input}
              onChange={(event) => {
                if (onInputChange) {
                  onInputChange(event.target.value);
                }
                setInput(event.target.value);
              }}
              InputProps={{
                classes: {
                  root: classes.InputOutlinedRoot,
                  focused: classes.InputOutlinedRootFocused,
                  input: classes.InputField,
                },
              }}
            />
          </Grid>
        </Grid>
      </form>
      {searching && (
        <div
          id="linear-progress"
          style={{
            width: '100%',
            position: 'absolute',
            bottom: -4,
          }}
        >
          <LinearProgress variant="indeterminate" color="primary" />
        </div>
      )}
    </Box>
  );
};

export default SearchStrip;

const useStyles = makeStyles((theme) => ({
  Container: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(theme.typography.pxToRem(20), 0),
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(theme.typography.pxToRem(40), 0),
    },
  },
  // InputOutlinedRoot: {
  //   color: '#fff',
  //   fontWeight: 400,
  //   backgroundColor: lighten(theme.palette.primary.main, 0.38),
  //   border: `1px solid #fff`,

  //   '& .MuiInputAdornment-root': {
  //     color: '#fff',
  //   },

  //   '& input::-webkit-input-placeholder': {
  //     color: '#fff',
  //   },
  //   '& input:-moz-placeholder': {
  //     color: '#fff',
  //     opacity: 1,
  //   },
  //   '& input::-moz-placeholder': {
  //     color: '#fff',
  //     opacity: 1,
  //   },
  //   '& input:-ms-input-placeholder': {
  //     color: '#fff',
  //   },
  //   '& input::-ms-input-placeholder': {
  //     color: '#fff',
  //   },

  //   '& input::placeholder': {
  //     color: '#fff',
  //   },
  // },
  // InputOutlinedRootFocused: {
  InputOutlinedRoot: {
    background: '#fff',
    fontWeight: 400,
    color: theme.palette.primary.main,
    border: `1px solid #fff`,

    '& .MuiInputAdornment-root': {
      color: theme.palette.primary.main,
    },

    '& input::-webkit-input-placeholder': {
      color: lighten(theme.palette.primary.main, 0.38),
    },
    '& input:-moz-placeholder': {
      color: lighten(theme.palette.primary.main, 0.38),
      opacity: 1,
    },
    '& input::-moz-placeholder': {
      color: lighten(theme.palette.primary.main, 0.38),
      opacity: 1,
    },
    '& input:-ms-input-placeholder': {
      color: lighten(theme.palette.primary.main, 0.38),
    },
    '& input::-ms-input-placeholder': {
      color: lighten(theme.palette.primary.main, 0.38),
    },

    '& input::placeholder': {
      color: lighten(theme.palette.primary.main, 0.38),
    },
  },
  InputOutlinedRootFocused: {
    '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: `1px solid transparent`,
      boxShadow: `0 0 0 5px ${alpha('#fefefe', 0.1)}`,
    },
  },
  InputField: {
    '&::-ms-clear': { cursor: 'pointer' },
    '&::-ms-reveal': { cursor: 'pointer' },

    [[
      '&::-webkit-search-decoration',
      '&::-webkit-search-cancel-button',
      '&::-webkit-search-results-button',
      '&::-webkit-search-results-decoration',
    ].join(',')]: { cursor: 'pointer' },
  },
}));
