const URIS = {
  SIGN_IN_WITH_USERNAME_AND_PASSWORD: '/auth/sign-in',
  LOGOUT: '/auth/sign-out',
  UPDATE_HOSPITAL_PROFILE: '/hospital/profile/update',
  VIEW_HOSPITAL_DETAILS: '/hospital/profile/view',
  LIST_HOSPITAL_BOOKINGS: '/hospital/bookings',
  UPDATE_BOOKING_STATUS: '/users/packages/status_update',
  LIST_PACKAGE_CATEGORIES: '/public/list_categories',
  LIST_PACKAGES: '/hospital/packages/list',
  ADD_PACKAGES: '/hospital/packages/add',
  UPDATE_PACKAGES: '/users/packages/update',
  REMOVE_PACKAGE: '/users/packages/remove',
  LIST_HOSPITALS: '/public/list_hospital',
};

let AVOID_ERROR_CHECKS = [];

const setAvoidErrorChecks = ({ url }) => {
  if (AVOID_ERROR_CHECKS.includes(url)) return;

  AVOID_ERROR_CHECKS.push(url);
};

const removeURLfromAvoidErrorChecks = ({ url }) => {
  AVOID_ERROR_CHECKS = AVOID_ERROR_CHECKS.filter((item) => item !== url);
};

export {
  AVOID_ERROR_CHECKS,
  setAvoidErrorChecks,
  removeURLfromAvoidErrorChecks,
};

export default URIS;
