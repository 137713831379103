import React from 'react';
import { makeStyles } from '@material-ui/core';

import NavItem from './NavItem';

const NavList = ({ navList = [], vertical = false }) => {
  const classes = useStyles();

  return (
    <ul
      className={[
        classes.RightNavList,
        vertical ? classes.RightNavListVertical : '',
      ].join(' ')}
    >
      {navList.map((item) => (
        <NavItem key={item.key} {...item} />
      ))}
    </ul>
  );
};

export default NavList;

const useStyles = makeStyles((theme) => ({
  RightNavList: {
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridColumnGap: theme.typography.pxToRem(40),
    columnGap: theme.typography.pxToRem(40),
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '80px',
  },
  RightNavListVertical: {
    gridAutoFlow: 'row',
    gridRowGap: theme.typography.pxToRem(20),
    rowGap: theme.typography.pxToRem(20),
    height: 'auto',
    padding: '30px',
    alignItems: 'flex-start',

    '& > li': {
      padding: '0.1rem 0',
    },
  },
}));
