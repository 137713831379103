import {
  Box,
  makeStyles,
  Tooltip,
  withStyles,
  Button,
  ClickAwayListener,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';

import { theme } from '../../../App';
import TextField from '../../TextField';
import StyleButton from '../StyleButton';
import { useRef, useState } from 'react';

const initialUrlState = {
  value: '',
  touched: false,
};

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a
      href={url}
      style={{
        textDecoration: 'underline',
        color: theme.palette.secondary.main,
      }}
    >
      {props.children}
    </a>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export const linkDecorator = {
  strategy: findLinkEntities,
  component: Link,
};

const validateUrl = (url) =>
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
    url,
  );

const LinkEntityControls = ({
  editorState,
  focusEditor,
  confirmLink,
  removeLink,
}) => {
  const classes = useStyles();

  const selection = editorState.getSelection();
  let url = '';
  let selectedText = '';

  if (!selection.isCollapsed()) {
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const endOffset = editorState.getSelection().getEndOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

    selectedText = blockWithLinkAtBeginning.text.slice(startOffset, endOffset);

    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      url = linkInstance.getData().url || '';
    }
  }

  const [urlTextFieldState, setUrlTextFieldState] = useState(initialUrlState);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const inputRef = useRef();

  const correctUrl = validateUrl(urlTextFieldState.value);

  return (
    <ClickAwayListener onClickAway={setTooltipOpen.bind(null, false)}>
      <HtmlTooltip
        open={tooltipOpen}
        TransitionProps={{
          onEntered: () => {
            inputRef.current.focus();
          },
          onExited: () => {
            setUrlTextFieldState(initialUrlState);
            focusEditor();
          },
        }}
        arrow
        title={
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <TextField
                ref={inputRef}
                onChange={(e) =>
                  setUrlTextFieldState((curState) => ({
                    ...curState,
                    value: e.target.value,
                  }))
                }
                error={!correctUrl && urlTextFieldState.touched}
                value={urlTextFieldState.value}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                  }
                }}
                onBlur={(e) =>
                  setUrlTextFieldState((curState) => ({
                    ...curState,
                    touched: true,
                  }))
                }
                style={{ marginTop: 8, marginBottom: 8 }}
              />
              <Box color="error.main">
                {!correctUrl && urlTextFieldState.touched ? 'Invalid URL' : ''}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              marginLeft={1}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.tooltipButtons}
                style={{ marginBottom: 4 }}
                onClick={(e) => {
                  e.preventDefault();
                  if (urlTextFieldState.value) {
                    confirmLink(urlTextFieldState.value);
                    setTooltipOpen(false);
                  } else {
                    inputRef.current.focus();
                  }
                }}
              >
                <CheckIcon fontSize="small" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.tooltipButtons}
                style={{ marginTop: 4 }}
                onClick={(e) => {
                  e.preventDefault();
                  removeLink();
                  setTooltipOpen(false);
                }}
              >
                <DeleteOutlineTwoToneIcon fontSize="small" />
              </Button>
            </Box>
          </Box>
        }
      >
        <StyleButton
          active={!!url}
          onToggle={
            !selection.isCollapsed()
              ? () => {
                  setUrlTextFieldState((curState) => ({
                    ...curState,
                    value:
                      url || (validateUrl(selectedText) ? selectedText : ''),
                  }));
                  setTooltipOpen((curState) => !curState);
                }
              : () => {}
          }
          label="Link"
          children={<LinkIcon />}
        />
      </HtmlTooltip>
    </ClickAwayListener>
  );
};

export default LinkEntityControls;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 360,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.07)',
    padding: theme.spacing(0.8, 1.4),
  },
  popper: {
    pointerEvents: 'auto',
    zIndex: 998,
  },
  arrow: {
    color: theme.palette.background.paper,
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.07)',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  tooltipButtons: {
    minWidth: 0,
    borderRadius: '50%',
    width: theme.typography.pxToRem(30),
    height: theme.typography.pxToRem(30),
    padding: 0,
  },
}));