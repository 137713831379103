import { createSlice } from '@reduxjs/toolkit';

import { snackbarType } from '../../components/SnackBarAlert';

const initialState = {
  networkFetching: false,
  loadingMessage: null,

  snackBarState: {
    message: null,
    type: snackbarType.ERROR,
    duration: 2000,
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.networkFetching = payload.networkFetching;
      state.loadingMessage = payload.loadingMessage;
    },
    setSnackbar(state, { payload }) {
      state.snackBarState.message = payload.message;
      state.snackBarState.type = payload.type;
      state.snackBarState.duration = payload.duration || 2000;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
