import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const TransitionSwitch = (props) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <TransitionGroup component="div" className={classes.AppContainer}>
      <CSSTransition
        key={location.key}
        timeout={450}
        classNames={classes.Animate}
        mountOnEnter={false}
        unmountOnExit={true}
      >
        <Switch location={location} {...props} />
      </CSSTransition>
    </TransitionGroup>
  );
};

export default TransitionSwitch;

const useStyles = makeStyles((theme) => ({
  AppContainer: {
    position: 'relative',
    height: '100vh',
    width: '100%',
  },

  Animate: {
    '&-appear.fade, &-enter.fade': {
      opacity: 0,
    },
    '&-appear-active.fade, &-enter&-enter-active.fade': {
      opacity: 1,
      transition: 'opacity 450ms ease-in',
    },

    '&-exit.fade': {
      opacity: 1,
    },

    '&-exit&-exit-active.fade': {
      opacity: 0,
      transition: 'opacity 450ms ease-out',
    },
  },
}));
