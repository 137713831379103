import React from 'react';
import { Grid } from '@material-ui/core';
import { CompositeDecorator } from 'draft-js';

import LinkEntityControls, { linkDecorator } from './LinkEntityControls';

export const decorator = new CompositeDecorator([linkDecorator]);

const EntityControls = ({
  editorState,
  focusEditor,
  confirmLink,
  removeLink,
}) => {
  return (
    <Grid container direction="row">
      <Grid item>
        <LinkEntityControls
          editorState={editorState}
          focusEditor={focusEditor}
          confirmLink={confirmLink}
          removeLink={removeLink}
        />
      </Grid>
    </Grid>
  );
};

export default EntityControls;
