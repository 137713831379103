import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from '../axios';
import { uiActions } from '../store/reducers/ui-slice';
import useSnackbar from './useSnackbar';
import { setAvoidErrorChecks, removeURLfromAvoidErrorChecks } from '../api';

class HttpError extends Error {
  constructor(message, responseData) {
    super(message);
    this.name = 'HttpError';
    this.responseData = responseData;
  }
}

const useApiCall = ({ listTemplate = false } = {}) => {
  const dispatch = useDispatch();

  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { showErrorSnackbar } = useSnackbar();

  const myFetch = useCallback(
    async ({
      loadingMessage = '',
      method = 'post',
      url,
      payload = {},
      onSuccess = (data) => {},
      onFailed = (errorMessage, data) => {},
    }) => {
      setResponseData(null);

      if (loadingMessage) {
        dispatch(
          uiActions.setLoading({
            networkFetching: true,
            loadingMessage,
          }),
        );
      }

      if (listTemplate) {
        setAvoidErrorChecks({ url });
      }

      setIsLoading(true);

      let data = null;

      try {
        const response = await axios[method](url, payload);

        data = response.data;

        if (data?.statusCode === 200 || data?.statusCode === 201) {
          onSuccess(data);
        } else {
          throw new Error('http-error');
        }

        dispatch(
          uiActions.setLoading({
            networkFetching: false,
            loadingMessage: null,
          }),
        );

        setIsLoading(false);

        setResponseData(data);

        if (listTemplate) {
          removeURLfromAvoidErrorChecks({ url });
        }

        return data;
      } catch (error) {
        // * console.log(error); // if you need to log these errors to a server or something

        const errorMessage =
          data?.message ||
          'Oops! Something went wrong. Please try again later...';

        dispatch(
          uiActions.setLoading({
            networkFetching: false,
            loadingMessage: null,
          }),
        );

        setIsLoading(false);

        onFailed(errorMessage, data);

        if (listTemplate && data?.statusCode !== 404) {
          showErrorSnackbar(errorMessage);
        }

        if (listTemplate) {
          removeURLfromAvoidErrorChecks({ url });
        }

        throw new HttpError(errorMessage, data);
      }
    },
    [dispatch, listTemplate, showErrorSnackbar],
  );

  return { myFetch, responseData, setResponseData, isLoading };
};

export default useApiCall;
