import React from 'react';
import { colors, makeStyles, useTheme } from '@material-ui/core';
import { SyncLoader } from 'react-spinners';

const DialogLoaderSlide = React.forwardRef(
  ({ loadMessage = 'Loading' }, ref) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        ref={ref}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SyncLoader color={theme.palette.primary.main} />
        </div>
        {!!loadMessage && (
          <div className={classes.LoaderMessage}>{loadMessage}...</div>
        )}
      </div>
    );
  },
);

export default DialogLoaderSlide;

const useStyles = makeStyles((theme) => ({
  LoaderMessage: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(16),
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(28),
    color: colors.grey[500],
  },
}));
