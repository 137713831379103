import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Scrollbars from '../components/ScrollBars';

const Page = ({
  children,
  transitionType = 'fade',
  className = '',
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box
      className={[transitionType, className, classes.Page].join(' ')}
      {...props}
    >
      <Scrollbars className={classes.ScrollTrackStyles}>{children}</Scrollbars>
    </Box>
  );
};

export default Page;

const useStyles = makeStyles((theme) => ({
  Page: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    paddingRight: theme.spacing(0.1),
    fontSize: theme.typography.pxToRem(16),
  },

  ScrollTrackStyles: {
    '& > div:last-child': {
      zIndex: 999,
    },
  },
}));
