import React from 'react';
import { useTheme } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const PasswordErrorMessage = ({ children, condition }) => {
  const theme = useTheme();

  const iconProps = {
    fontSize: 'small',
    style: { color: 'inherit', marginRight: '3px' },
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1,
        color: condition
          ? theme.palette.success.main
          : theme.palette.error.main,
      }}
    >
      {condition ? (
        <CheckCircleOutlineIcon {...iconProps} />
      ) : (
        <ErrorOutlineIcon {...iconProps} />
      )}{' '}
      {children}
    </div>
  );
};

export default PasswordErrorMessage;
