import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetails: null,
  fetchingUserDetails: {
    loading: true,
    errorMessage: null,
  },
};

const profileDetailsSlice = createSlice({
  name: 'profile-details',
  initialState,
  reducers: {
    setUserDetails(state, { payload }) {
      if (state.userDetails) {
        state.userDetails = { ...state.userDetails, ...payload };
      } else {
        state.userDetails = payload;
      }
    },
    setFetchingUserDetails(state, { payload }) {
      state.fetchingUserDetails = payload;
    },
    reset(state) {
      state.userDetails = null;
      state.fetchingUserDetails = {
        loading: true,
        errorMessage: null,
      };
      state.calculatedCredits = null;
      state.fetchingCalculatedCredits = {
        loading: true,
        errorMessage: null,
      };
    },
  },
});

export const profileDetailsActions = profileDetailsSlice.actions;

export default profileDetailsSlice.reducer;
