import React, { Fragment } from 'react';
import {
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery,
  Box,
  Divider,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const BookingItemLoading = ({ divider = false }) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: (document.body.getBoundingClientRect()?.width || 0) >= 960,
  });

  const patientName = (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      xs={12}
      md={3}
      spacing={matchesUpMd ? 1 : 0}
      direction={matchesUpMd ? 'column' : 'row'}
      style={{
        lineHeight: matchesUpMd ? 1.5 : 1,
        margin: matchesUpMd ? 0 : `${theme.typography.pxToRem(6)} 0`,
      }}
    >
      <Skeleton width={theme.typography.pxToRem(200)} />
    </Grid>
  );

  const puchasedDate = (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      xs={5}
      md={2}
      spacing={matchesUpMd ? 1 : 0}
    >
      <Grid
        item
        className={[classes.SmallHeadingText, classes.FontSizeUpSm].join(' ')}
      >
        <Skeleton width={theme.typography.pxToRem(100)} />
      </Grid>
      <Grid
        item
        className={[classes.SmallText, classes.FontSizeUpSm].join(' ')}
      >
        <Skeleton width={theme.typography.pxToRem(80)} />
      </Grid>
    </Grid>
  );

  const validUpto = (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      xs={5}
      md={2}
      spacing={matchesUpMd ? 1 : 0}
    >
      <Grid
        item
        className={[classes.SmallHeadingText, classes.FontSizeUpSm].join(' ')}
      >
        <Skeleton width={theme.typography.pxToRem(100)} />
      </Grid>
      <Grid
        item
        className={[classes.SmallText, classes.FontSizeUpSm].join(' ')}
      >
        <Skeleton width={theme.typography.pxToRem(80)} />
      </Grid>
    </Grid>
  );

  return (
    <Box
      style={
        matchesUpMd && divider
          ? { marginBottom: theme.typography.pxToRem(8) }
          : {}
      }
    >
      {matchesUpMd ? (
        <Skeleton
          variant="rect"
          width="100%"
          height={theme.typography.pxToRem(120)}
        />
      ) : (
        <Fragment>
          <Grid
            container
            alignItems="center"
            spacing={1}
            className={classes.ItemContainer}
          >
            <Grid item>
              <Skeleton variant="text" width={theme.typography.pxToRem(100)} />
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item container xs={7} md={3} direction="column">
                  <Grid item style={{ width: '100%' }}>
                    <h6
                      style={{ width: matchesUpMd ? '90%' : '95%' }}
                      className={classes.Heading}
                    >
                      <Skeleton width={theme.typography.pxToRem(150)} />
                    </h6>
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant="text"
                      width={theme.typography.pxToRem(100)}
                    />
                  </Grid>
                </Grid>
                {matchesUpMd && (
                  <>
                    {puchasedDate}
                    {validUpto}
                    {patientName}
                  </>
                )}

                <Grid
                  item
                  xs={5}
                  md={2}
                  className={[
                    classes.SmallHeadingText,
                    classes.FontSizeUpSm,
                  ].join(' ')}
                  style={{
                    lineHeight: 1,
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <h1 style={{ display: 'flex', justifyContent: 'center' }}>
                    <Skeleton width={theme.typography.pxToRem(75)} />
                  </h1>
                </Grid>
              </Grid>
            </Grid>

            {!matchesUpMd ? patientName : null}

            {!matchesUpMd && (
              <Grid item container justifyContent="space-around">
                {puchasedDate}
                <Divider orientation="vertical" flexItem />
                {validUpto}
              </Grid>
            )}
          </Grid>
          {divider && <Divider />}
        </Fragment>
      )}
    </Box>
  );
};

export default BookingItemLoading;

const useStyles = makeStyles((theme) => ({
  ItemContainer: {
    margin: theme.spacing(theme.typography.pxToRem(16), 0),
  },
  SmallHeadingText: {
    fontSize: theme.typography.pxToRem(12),
  },
  SmallText: {
    fontSize: theme.typography.pxToRem(12),
  },
  FontSizeUpSm: {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  Heading: {
    margin: 0,
    fontSize: theme.typography.pxToRem(18),

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));
