import React, { useCallback } from 'react';
import {
  Grid,
  Box,
  useTheme,
  makeStyles,
  useMediaQuery,
  colors,
} from '@material-ui/core';
import { SyncLoader } from 'react-spinners';
import produce from 'immer';

import Page from '../hoc/Page';
import PageHeading from '../components/PageHeading';
import PrimaryCard from '../components/Card/PrimaryCard';
import useApiCall from '../hooks/useApiCall';
import URIS from '../api';
import BookingItem from '../containers/BookingItem';
import BookingItemLoading from '../containers/BookingItem/BookingItemLoading';
import useDialog from '../hooks/useDialog';
import usePagination, { TEMPLATES_AVAILABLE } from '../hooks/usePagination';

const Booking = () => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesUpSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const matchesUpMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const { myFetch, responseData, setResponseData, isLoading } = useApiCall({
    listTemplate: true,
  });
  
  const {
    dialogComponent: confirmationDialog,
    openDialog: openConfirmationDialog,
  } = useDialog({
    confirmationTemplete: true,
  });
  const { dialogComponent: viewMoreDialog, openDialog: openViewMoreDialog } =
    useDialog();

  const { List } = usePagination({
    template: TEMPLATES_AVAILABLE.LOAD_MORE_BUTTON,
    touchScreenTemplate: TEMPLATES_AVAILABLE.LAZY_LOAD,
    rowsPerBatch: 10,
    loadData: useCallback(
      async ({ pageNo, rowsPerPage }) => {
        try {
          const data = await myFetch({
            url: URIS.LIST_HOSPITAL_BOOKINGS,
            payload: {
              pattern: '',
              table_config_rows_per_page: `${rowsPerPage}`,
              table_config_page_no: `${pageNo}`,
            },
          });

          return {
            dataList: data?.response?.response_bookings,
            currentPage: data?.current_page,
            rowsPerPage: data?.rows_per_page,
            totalPagesCount: data?.total_pages_count,
          };
        } catch (error) {
          setResponseData({
            response: {
              response_bookings: [],
              total_sell: '0',
              pending_bookings: '0',
            },
          });

          throw error;
        }
      },
      [myFetch, setResponseData],
    ),
  });

  return (
    <Page>
      <Box className={classes.PageContent}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            md={10}
            lg={12}
            spacing={3}
            style={{
              margin: theme.spacing(
                0,
                'auto',
                theme.typography.pxToRem(20),
                'auto',
              ),
            }}
          >
            {confirmationDialog}
            {viewMoreDialog}
            <Grid item xs={12} lg={4}>
              <PageHeading
                autoResponsive={false}
                classes={{ head: classes.PageHeading }}
              >
                All
                <br />
                <strong>Bookings</strong>
              </PageHeading>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Box className={classes.CardsContainer}>
                <PrimaryCard
                  cardColor="#4A899C"
                  value={
                    isLoading ? (
                      <SyncLoader color={theme.palette.primary.contrastText} />
                    ) : (
                      responseData?.response?.total_sell || '0'
                    )
                  }
                  title="Total Sold"
                  autoResponsive={false}
                  classes={{
                    cardValue: classes.CardValue,
                    cardTitle: classes.CardTitle,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Box className={classes.CardsContainer}>
                <PrimaryCard
                  cardColor="#472C94"
                  value={
                    isLoading ? (
                      <SyncLoader color={theme.palette.primary.contrastText} />
                    ) : (
                      responseData?.response?.pending_bookings || '0'
                    )
                  }
                  title="Bookings Pending"
                  autoResponsive={false}
                  classes={{
                    cardValue: classes.CardValue,
                    cardTitle: classes.CardTitle,
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <List
              LoadingListItem={({ lastColumn }) => (
                <BookingItemLoading divider={!lastColumn} />
              )}
              NoDataFoundComponent={(props) => (
                <div
                  {...props}
                  style={{
                    fontWeight: 500,
                    fontSize: theme.typography.pxToRem(25),
                    height: theme.typography.pxToRem(200),
                    padding: theme.spacing(
                      theme.typography.pxToRem(50),
                      theme.typography.pxToRem(20),
                    ),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: colors.grey[400],
                  }}
                >
                  No Bookings yet!
                </div>
              )}
              lazyLoadHeight={matchesUpMd ? 124 : matchesUpSm ? 200 : 194}
              lazyLoadOffset={300}
              lazyLoadNextRowOffset={100}
            >
              {({ colIndex, rowIndex, lastColumn, ...props }) => (
                <BookingItem
                  {...props}
                  openConfirmationDialog={openConfirmationDialog}
                  openViewMoreDialog={openViewMoreDialog}
                  onChangePendingStatus={setResponseData.bind(
                    null,
                    produce((curState) => {
                      curState.response.pending_bookings -= 1;
                      return;
                    }),
                  )}
                  divider={!lastColumn}
                />
              )}
            </List>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Booking;

const useStyles = makeStyles((theme) => ({
  PageContent: {
    width: '90%',
    maxWidth: theme.typography.pxToRem(1400),
    margin: theme.spacing(
      theme.typography.pxToRem(120),
      'auto',
      theme.typography.pxToRem(50),
      'auto',
    ),
  },
  PageHeading: {
    fontSize: theme.typography.pxToRem(40),

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(65),
    },
  },
  CardsContainer: {
    height: theme.typography.pxToRem(190),

    [theme.breakpoints.up('sm')]: {
      height: theme.typography.pxToRem(200),
    },

    [theme.breakpoints.up('md')]: {
      height: theme.typography.pxToRem(280),
    },

    [theme.breakpoints.up('lg')]: {
      height: theme.typography.pxToRem(270),
    },
  },
  CardValue: {
    fontSize: theme.typography.pxToRem(45),

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(60),
    },
  },
  CardTitle: {
    fontSize: theme.typography.pxToRem(22),

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(20),
    },

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(28),
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(30),
    },
  },
  table: {
    minWidth: '100%',
    boxShadow: 'none',
  },
}));
