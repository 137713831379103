import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useCallback,
} from 'react';
import { Grid, Box, makeStyles, useTheme } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';

import PageHeading from '../components/PageHeading';
import Page from '../hoc/Page';
import AddPackagesForm from '../containers/AddPackagesForm';
import useApiCall from '../hooks/useApiCall';
import URIS from '../api';
import useSnackbar from '../hooks/useSnackbar';

const EditPackages = () => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    location: { pathname },
    push,
  } = useHistory();
  const params = useParams();

  const [init, setInit] = useState(false);
  const [formInit, setFormInit] = useState(false);

  const headingRef = useRef(
    <Fragment>
      <strong>Add</strong> Packages
    </Fragment>,
  );

  const { myFetch: fetchPackageDetails, responseData: packageDetails } =
    useApiCall({ listTemplate: true });

  const { showErrorSnackbar } = useSnackbar();

  const fetchEditDetails = useCallback(async () => {
    try {
      await fetchPackageDetails({
        url: URIS.LIST_PACKAGES,
        payload: {
          pattern: '',
          table_config_rows_per_page: '10',
          table_config_page_no: '1',
          package_id: params?.package_id || '',
        },
      });
    } catch (error) {
      showErrorSnackbar(
        "Please check your internet connection or the package you entered doesn't exist in our database",
      );

      push('/packages');
    }
  }, [fetchPackageDetails, params, push, showErrorSnackbar]);

  useEffect(() => {
    if (!init) {
      setInit(true);

      if (pathname.startsWith('/edit-packages')) {
        headingRef.current = (
          <Fragment>
            <strong>Edit</strong> Packages
          </Fragment>
        );

        fetchEditDetails().then(() => {
          setFormInit(true);
        });
      } else {
        setFormInit(true);
      }
    }
  }, [fetchEditDetails, init, pathname]);

  return (
    init && (
      <Page>
        <Box className={classes.PageContent}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={8}
              md={9}
              style={{
                margin: theme.spacing(
                  theme.typography.pxToRem(20),
                  'auto',
                  theme.typography.pxToRem(50),
                  'auto',
                ),
              }}
            >
              <PageHeading
                autoResponsive={false}
                classes={{ head: classes.PageHeading }}
              >
                {headingRef.current}
              </PageHeading>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              md={9}
              style={{
                margin: '0 auto',
              }}
            >
              {formInit ? (
                <AddPackagesForm
                  editInfo={
                    Array.isArray(packageDetails?.response?.response_packages)
                      ? packageDetails?.response?.response_packages[0]
                      : null
                  }
                  packageId={params?.package_id}
                />
              ) : (
                <Box
                  height={theme.typography.pxToRem(300)}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SyncLoader color={theme.palette.primary.main} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Page>
    )
  );
};

export default EditPackages;

const useStyles = makeStyles((theme) => ({
  PageContent: {
    width: '90%',
    maxWidth: theme.typography.pxToRem(1920),
    margin: theme.spacing(
      theme.typography.pxToRem(120),
      'auto',
      theme.typography.pxToRem(50),
      'auto',
    ),
  },
  PageHeading: {
    fontSize: theme.typography.pxToRem(40),

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(65),
    },
  },
}));
