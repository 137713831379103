import React, { useEffect, useState, useMemo, memo, Fragment } from 'react';
import {
  useTheme,
  Box,
  makeStyles,
  Divider,
  colors,
  useMediaQuery,
  MenuItem,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Select from '../../components/Select';
import Button from '../../components/Button';
import IndianRupee from '../../icons/IndianRupee';
import SelectItems from './SelectItems';
import DialogLoaderSlide from '../../components/Loader/DialogLoaderSlide';
import useApiCall from '../../hooks/useApiCall';
import useSnackbar from '../../hooks/useSnackbar';
import URIS from '../../api';

const statusMapping = [
  { id: 'pending', label: 'Pending' },
  { id: 'in_progress', label: 'In-progress' },
  { id: 'completed', label: 'Done' },
];

const BookingItem = ({
  profit,
  reward_cost,
  selling_price,
  purchase_id = '',
  ref_id = '',
  seller_id,
  hospital_cost = '',
  purchase_date = '',
  validity_upto = '',
  purchase_status = '',
  hospital_name,
  package_name = '',
  user_name = '',
  shipment_details,
  openConfirmationDialog = () => {},
  openViewMoreDialog = () => {},
  onChangePendingStatus,
  divider = true,
  updateListData,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesUp380p = useMediaQuery('(min-width: 380px)');
  const matchesUp500p = useMediaQuery('(min-width: 500px)');
  const matchesUp580p = useMediaQuery('(min-width: 580px)');
  const matchesBetween960and1050p = useMediaQuery(
    '(min-width: 960px) and (max-width: 1050px)',
  );
  const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const { myFetch: updateBookingStatus } = useApiCall();
  const { showSuccessSnackbar } = useSnackbar();

  const [bookingStatus, setBookingStatus] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const bookingStatusListFromProps = useMemo(
    () =>
      ['pending', 'in_progress', 'completed'].slice(
        parseInt(purchase_status) - 1,
      ),
    [purchase_status],
  );

  useEffect(() => {
    setBookingStatus(bookingStatusListFromProps[0]);
  }, [bookingStatusListFromProps]);

  const changeStatusApiRequest = async (status) => {
    try {
      await updateBookingStatus({
        url: URIS.UPDATE_BOOKING_STATUS,
        payload: {
          purchase_status: status,
          booking_id: purchase_id,
        },
      });

      // TODO: Update the list data
      if (bookingStatus === 'pending') {
        onChangePendingStatus();
      }

      updateListData({
        ref_id,
        purchase_id,
        hospital_cost,
        purchase_date,
        validity_upto,
        purchase_status:
          statusMapping.findIndex((item) => item.id === status) + 1,
        package_name,
        user_name,
        ...props,
      });

      return 'successful';
    } catch (error) {
      return 'error';
    }
  };

  const onStatusChange = (event) => {
    const fromStatus = bookingStatus;
    const toStatus = event.target.value;

    openConfirmationDialog(null, {
      autoCloseWhenButtonClicked: false,
      autoCloseWhenBackdropClicked: true,
      minHeight: matchesUp580p
        ? theme.typography.pxToRem(190)
        : matchesUp500p
        ? theme.typography.pxToRem(200)
        : theme.typography.pxToRem(210),
      content: (
        <span style={{ lineHeight: 1.8 }}>
          By clicking <strong>Confirm</strong>, you are going to change the
          status of the booking (Ref ID: {ref_id}) from{' '}
          <strong>
            {statusMapping.find((item) => item.id === fromStatus).label}
          </strong>{' '}
          to{' '}
          <strong>
            {statusMapping.find((item) => item.id === toStatus).label}
          </strong>
          !
        </span>
      ),
      slides: [<DialogLoaderSlide loadMessage="Changing the status" />],
      onClickButtons: async (
        selected,
        closeDialog,
        updateSlides,
        blockBackdropClose,
      ) => {
        if (selected === 'confirm') {
          blockBackdropClose();
          updateSlides();
          const message = await changeStatusApiRequest(toStatus);

          if (message === 'successful') {
            showSuccessSnackbar(
              `Status of the booking (Ref ID: ${ref_id}) has been updated to ${
                statusMapping.find((item) => item.id === toStatus).label
              }!`,
              5000,
            );
          }

          closeDialog();
        } else {
          closeDialog();
        }
      },
    });
  };

  const patientName = (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      xs={12}
      md={3}
      spacing={matchesUpMd ? 1 : 0}
      direction={matchesUpMd ? 'column' : 'row'}
      style={{
        lineHeight: matchesUpMd ? 1.5 : 1,
        margin: matchesUpMd ? 0 : `${theme.typography.pxToRem(6)} 0`,
      }}
    >
      <Grid
        item
        className={[classes.SmallHeadingText, classes.FontSizeUpSm].join(' ')}
      >
        {matchesUpSm ? 'Patient Name:' : 'Patient:'}
      </Grid>{' '}
      <Grid
        item
        style={
          matchesUpMd
            ? { textAlign: 'center' }
            : { marginLeft: theme.typography.pxToRem(10) }
        }
        className={
          matchesUpMd ? [classes.Truncate, classes.FontSizeUpSm].join(' ') : ''
        }
      >
        {user_name}
      </Grid>
    </Grid>
  );

  const onClickViewMore = () => {
    openViewMoreDialog(null, {
      autoCloseWhenButtonClicked: false,
      autoCloseWhenBackdropClicked: true,
      icon: false,
      title: <></>,
      actions: <></>,
      content: ({ closeDialog, updateSlides }) => (
        <div>
          <strong>Package Name:</strong> {package_name} <br />
          <strong>Hospital Earnings:</strong> Rs. {hospital_cost} <br />
          <strong>Selling Price:</strong> Rs. {selling_price} <br />
          <strong>Profit:</strong> Rs. {profit} <br />
          <strong>Rewards Given:</strong> Rs. {reward_cost} <br />
          <strong>Purchase Id:</strong> {purchase_id} <br />
          <strong>Reference Id:</strong> {ref_id} <br />
          <strong>Seller Id:</strong> {seller_id} <br />
          <strong>Purchase Date:</strong>{' '}
          {dayjs(purchase_date).isValid()
            ? dayjs(purchase_date).format(
                matchesUpMd && !matchesUpLg ? 'MMM DD, YYYY' : 'MMMM DD, YYYY',
              )
            : ''}{' '}
          <br />
          <strong>Valid Upto:</strong>{' '}
          {dayjs(validity_upto).isValid()
            ? dayjs(validity_upto).format(
                matchesUpMd && !matchesUpLg ? 'MMM DD, YYYY' : 'MMMM DD, YYYY',
              )
            : ''}{' '}
          <br />
          <strong>Purchase Status:</strong> {purchase_status} <br />
          <strong>Hospital Name:</strong> {hospital_name} <br />
          {!!shipment_details && (
            <Fragment>
              <strong
                style={{
                  fontSize: '150%',
                  fontWeight: 500,
                  marginTop: 30,
                  display: 'block',
                  lineHeight: 1,
                }}
              >
                Shipment Details:
              </strong>
              <br />
              <ul style={{ margin: 0 }}>
                {Object.entries(shipment_details || {}).map(([key, value]) => (
                  <li key={key}>
                    <strong>{key}:</strong> {value}
                  </li>
                ))}
              </ul>
            </Fragment>
          )}
        </div>
      ),
    });
  };

  const puchasedDate = (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      xs={5}
      md={2}
      spacing={matchesUpMd ? 1 : 0}
    >
      <Grid
        item
        className={[classes.SmallHeadingText, classes.FontSizeUpSm].join(' ')}
      >
        Purchase Date:
      </Grid>
      <Grid
        item
        className={[classes.SmallText, classes.FontSizeUpSm].join(' ')}
      >
        {dayjs(purchase_date).isValid()
          ? dayjs(purchase_date).format(
              matchesUpMd && !matchesUpLg ? 'MMM DD, YYYY' : 'MMMM DD, YYYY',
            )
          : ''}
      </Grid>
    </Grid>
  );

  const validUpto = (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      xs={5}
      md={2}
      spacing={matchesUpMd ? 1 : 0}
    >
      <Grid
        item
        className={[classes.SmallHeadingText, classes.FontSizeUpSm].join(' ')}
      >
        Valid Upto:
      </Grid>
      <Grid
        item
        className={[classes.SmallText, classes.FontSizeUpSm].join(' ')}
      >
        {dayjs(validity_upto).isValid()
          ? dayjs(validity_upto).format(
              matchesUpMd && !matchesUpLg ? 'MMM DD, YYYY' : 'MMMM DD, YYYY',
            )
          : ''}
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      <Grid
        container
        alignItems="center"
        spacing={1}
        className={classes.ItemContainer}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          style={{
            margin: theme.spacing(theme.typography.pxToRem(-5), 0),
          }}
        >
          <Grid item className={classes.SmallHeadingText}>
            Ref ID:{' '}
            <span
              style={{
                marginLeft: theme.typography.pxToRem(10),
                color: '#000',
              }}
            >
              {ref_id}
            </span>
          </Grid>
          <Grid item>
            <Button
              size="small"
              style={{
                font: 'inherit',
                padding: theme.spacing(
                  theme.typography.pxToRem(4),
                  theme.typography.pxToRem(10),
                ),
                borderRadius: 2000,
              }}
              onClick={onClickViewMore}
            >
              <VisibilityIcon fontSize="small" htmlColor={colors.grey[500]} />
              <span
                className={classes.SmallHeadingText}
                style={{
                  marginLeft: theme.typography.pxToRem(6),
                  color: colors.grey[500],
                  textTransform: 'capitalize',
                }}
              >
                View more
              </span>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item container xs={7} md={3} direction="column">
              <Grid item style={{ width: '100%' }}>
                <h6
                  style={{ width: matchesUpMd ? '90%' : '95%' }}
                  className={[classes.Heading, classes.Truncate].join(' ')}
                >
                  {package_name}
                </h6>
              </Grid>
              <Grid item>
                <span
                  className={[
                    classes.SmallHeadingText,
                    classes.FontSizeUpSm,
                  ].join(' ')}
                >
                  Earnings:
                </span>{' '}
                <span
                  style={{ marginLeft: theme.typography.pxToRem(5) }}
                  className={[classes.SmallText, classes.FontSizeUpSm].join(
                    ' ',
                  )}
                >
                  <IndianRupee
                    style={{ fontSize: '80%' }}
                    htmlColor="currentColor"
                  />{' '}
                  {hospital_cost}
                </span>
              </Grid>
            </Grid>
            {matchesUpMd && (
              <>
                {puchasedDate}
                {validUpto}
                {patientName}
              </>
            )}
            {bookingStatus && (
              <Grid
                item
                xs={5}
                md={2}
                className={[
                  classes.SmallHeadingText,
                  classes.FontSizeUpSm,
                ].join(' ')}
                style={{
                  lineHeight: 1,
                  fontWeight: matchesUpMd ? 400 : 700,
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {bookingStatusListFromProps.length > 1 && (
                  <Box
                    maxWidth={theme.typography.pxToRem(220)}
                    minHeight={theme.typography.pxToRem(50)}
                    style={{
                      opacity: 0,
                      width: matchesUpSm
                        ? theme.typography.pxToRem(158)
                        : matchesUp380p
                        ? theme.typography.pxToRem(120)
                        : theme.typography.pxToRem(110),
                    }}
                  >
                    <Select
                      value={bookingStatus}
                      menuProps={{
                        classes: {
                          paper: classes.MenuPaperClass,
                        },
                        PaperProps: {
                          elevation: 2,
                        },
                      }}
                      onChange={onStatusChange}
                      onOpen={setMenuOpen.bind(null, true)}
                      onClose={setMenuOpen.bind(null, false)}
                    >
                      {!isNaN(parseInt(purchase_status)) &&
                      purchase_status >= 1 &&
                      purchase_status <= 3
                        ? bookingStatusListFromProps.map((item, index) => {
                            return (
                              <MenuItem
                                key={item}
                                value={item}
                                style={{ position: 'relative' }}
                                disabled={item === bookingStatus}
                              >
                                <SelectItems
                                  selected={item}
                                  style={{
                                    ...(!matchesUpSm ||
                                    matchesBetween960and1050p
                                      ? {
                                          fontSize: !matchesUpSm
                                            ? '80%'
                                            : '90%',
                                        }
                                      : {}),
                                  }}
                                />
                                {index <
                                  bookingStatusListFromProps.length - 1 && (
                                  <Divider
                                    style={{
                                      position: 'absolute',
                                      width: '100%',
                                      bottom: 0,
                                      left: 0,
                                    }}
                                  />
                                )}
                              </MenuItem>
                            );
                          })
                        : null}
                    </Select>
                  </Box>
                )}
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  style={{
                    transform: 'translate(-50%, -50%)',
                    pointerEvents: 'none',
                    display: 'flex',
                    direction: 'row',
                    alignItems: 'center',
                  }}
                  width="fit-content"
                >
                  <SelectItems selected={bookingStatus} />
                  {bookingStatusListFromProps.length > 1 && (
                    <Fragment>
                      {menuOpen ? (
                        <ExpandLessIcon
                          style={{
                            fontSize: '100%',
                            marginLeft: theme.typography.pxToRem(4),
                          }}
                          htmlColor={
                            bookingStatus === 'pending'
                              ? theme.palette.error.main
                              : bookingStatus === 'in_progress'
                              ? theme.palette.secondary.main
                              : '#000'
                          }
                        />
                      ) : (
                        <ExpandMoreIcon
                          style={{
                            fontSize: '100%',
                            marginLeft: theme.typography.pxToRem(4),
                          }}
                          htmlColor={
                            bookingStatus === 'pending'
                              ? theme.palette.error.main
                              : bookingStatus === 'in_progress'
                              ? theme.palette.secondary.main
                              : '#000'
                          }
                        />
                      )}
                    </Fragment>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>

        {!matchesUpMd ? patientName : null}

        {!matchesUpMd && (
          <Grid item container justifyContent="space-around">
            {puchasedDate}
            <Divider orientation="vertical" flexItem />
            {validUpto}
          </Grid>
        )}
      </Grid>
      {divider && <Divider />}
    </Fragment>
  );
};

export default memo(BookingItem);

const useStyles = makeStyles((theme) => ({
  ItemContainer: {
    margin: theme.spacing(theme.typography.pxToRem(16), 0),
  },
  SmallHeadingText: {
    fontSize: theme.typography.pxToRem(12),
    color: colors.grey[500],
  },
  SmallText: {
    fontSize: theme.typography.pxToRem(12),
  },
  FontSizeUpSm: {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  Truncate: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  Heading: {
    margin: 0,
    fontSize: theme.typography.pxToRem(18),

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
    },
  },
  MenuPaperClass: {
    marginTop: theme.typography.pxToRem(-8),

    '& .MuiList-root': {
      padding: 0,
    },

    '& .MuiListItem-root': {
      minHeight: 0,
      padding: theme.spacing(theme.typography.pxToRem(8), 0),
    },
  },
  ViewDialogPaper: {
    maxHeight: '90vh !important',
  },
}));

// {loading ? (
//                       <Box
//                         width={theme.typography.pxToRem(220)}
//                         minHeight={theme.typography.pxToRem(50)}
//                         display="flex"
//                         alignItems="center"
//                         justifyContent="center"
//                       >
//                         <PulseLoader size={10} color={theme.palette.secondary.main} />
//                       </Box>
//                     ) : (

// {loading ? (
//                     <h1 style={{ display: 'flex', justifyContent: 'center' }}>
//                       <Skeleton width={theme.typography.pxToRem(75)} />
//                     </h1>
//                   ) : purchase_status === '1' ? (
//                     <Grid
//                       container
//                       alignItems="center"
//                       justifyContent="center"
//                       style={{ color: theme.palette.error.main }}
//                     >
//                       <span
//                         style={{ marginRight: theme.typography.pxToRem(4) }}
//                       >
//                         <ErrorOutlineIcon color="inherit" />
//                       </span>
//                       <span>Pending</span>
//                     </Grid>
//                   ) : purchase_status === '2' ? (
//                     <Grid
//                       container
//                       alignItems="center"
//                       justifyContent="center"
//                       style={{ color: theme.palette.secondary.main }}
//                     >
//                       <span
//                         style={{ marginRight: theme.typography.pxToRem(4) }}
//                       >
//                         <CachedIcon color="inherit" />
//                       </span>
//                       <span>In-progress</span>
//                     </Grid>
//                   ) : purchase_status === '3' ? (
//                     <Grid
//                       container
//                       alignItems="center"
//                       justifyContent="center"
//                       style={{ color: theme.palette.success.main }}
//                     >
//                       <span
//                         style={{ marginRight: theme.typography.pxToRem(4) }}
//                       >
//                         <CheckCircleOutlineIcon color="inherit" />
//                       </span>
//                       <span>Done</span>
//                     </Grid>
//                   ) : null}
