import React, { useState, useRef, useImperativeHandle } from 'react';
import {
  Select as MuiSelect,
  MenuItem,
  makeStyles,
  Box,
} from '@material-ui/core';

import Input from '../Input';

const Select = React.forwardRef(
  (
    {
      children = null,
      options = [{ value: 'male', label: 'Male' }],
      value = '',
      onChange = () => {},
      selectContainerClassName = '',
      selectPlaceHolderTextClassName = '',
      placeholder = '',
      menuProps = {},
      ...props
    },
    ref,
  ) => {
    const classes = useStyles();

    const [menuWidth, setMenuWidth] = useState(300);
    const [menuOpen, setMenuOpen] = useState(false);
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current.focus(),
      value: inputRef.current.value,
      node: inputRef.current.node,
    }));

    return (
      <Box
        className={[classes.SelectContainer, selectContainerClassName].join(
          ' ',
        )}
      >
        <MuiSelect
          {...props}
          value={value}
          open={menuOpen}
          onOpen={() => {
            if (props.onOpen) {
              props.onOpen();
            }
            setMenuOpen(true);
          }}
          onClose={() => {
            if (props.onClose) {
              props.onClose();
            }
            setMenuOpen(false);
          }}
          onChange={onChange}
          inputRef={inputRef}
          classes={{
            root: classes.SelectRoot,
            icon: classes.SelectIcon,
          }}
          input={
            <Input
              {...(props.inputProps ? props.inputProps : {})}
              classes={{
                root: classes.InputBaseRoot,
              }}
            />
          }
          MenuProps={{
            TransitionProps: {
              onEnter: () =>
                setMenuWidth(
                  inputRef.current.node.getBoundingClientRect().width || 300,
                ),
            },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            style: {
              '--menu-width': `${menuWidth}px`,
            },
            ...menuProps,
            classes: {
              paper: [classes.MenuPaper, menuProps?.classes?.paper || ''].join(
                ' ',
              ),
            },
          }}
        >
          {children ||
            options.map(({ value = '', label = <></>, props = {} }) => (
              <MenuItem key={value} value={value} {...props}>
                {label}
              </MenuItem>
            ))}
        </MuiSelect>
        <div className={classes.PlaceHolderContainer}>
          <Box
            className={[
              classes.PlaceHolderText,
              selectPlaceHolderTextClassName,
            ].join(' ')}
          >
            {!value && !menuOpen && !!placeholder ? placeholder : ''}
          </Box>
        </div>
      </Box>
    );
  },
);

export default Select;

const useStyles = makeStyles(() => ({
  SelectContainer: {
    position: 'relative',
  },
  InputBaseRoot: {
    display: 'block',
    width: '100%',

    '& > div:first-child': {
      padding: '0 20px',
    },

    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: '1.6em',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  PlaceHolderContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    pointerEvents: 'none',
  },
  PlaceHolderText: {
    color: 'darkgrey',
    fontWeight: 'normal',
    lineHeight: 1,
    verticalAlign: 'middle',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 22,
  },
  SelectRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  SelectIcon: {
    margin: '0 0.4rem',
  },
  MenuPaper: {
    minWidth: 'fit-content !important',
    width: 'var(--menu-width, 324px)',
    margin: '5px 0 0 0',
  },
}));
