import React from 'react';
import { Grid, Box, useTheme, makeStyles } from '@material-ui/core';

import Page from '../hoc/Page';
import PageHeading from '../components/PageHeading';
import UpdateProfileForm from '../containers/UpdateProfileForm';

const Profile = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Page>
      <Box className={classes.PageContent}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={8}
            md={9}
            style={{
              margin: theme.spacing(
                theme.typography.pxToRem(20),
                'auto',
                theme.typography.pxToRem(50),
                'auto',
              ),
            }}
          >
            <PageHeading
              autoResponsive={false}
              classes={{ head: classes.PageHeading }}
            >
              <strong>Profile</strong>
            </PageHeading>
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={9}
            style={{
              margin: '0 auto',
            }}
          >
            <UpdateProfileForm />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Profile;

const useStyles = makeStyles((theme) => ({
  PageContent: {
    width: '90%',
    maxWidth: theme.typography.pxToRem(1920),
    margin: theme.spacing(
      theme.typography.pxToRem(120),
      'auto',
      theme.typography.pxToRem(50),
      'auto',
    ),
  },
  PageHeading: {
    fontSize: theme.typography.pxToRem(40),

    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(65),
    },
  },
}));
