import { configureStore } from '@reduxjs/toolkit';

import authReducer from './reducers/auth-slice';
import uiReducer from './reducers/ui-slice';
import profileDetailsReducer from './reducers/profile-details-slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
    profileDetails: profileDetailsReducer,
  },
});

export default store;
