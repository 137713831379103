import React from 'react';
import { Grid } from '@material-ui/core';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';

import StyleButton from './StyleButton';

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', children: <FormatBoldIcon /> },
  { label: 'Italic', style: 'ITALIC', children: <FormatItalicIcon /> },
  {
    label: 'Underline',
    style: 'UNDERLINE',
    children: <FormatUnderlinedIcon />,
  },
];

const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <Grid container direction="row">
      {INLINE_STYLES.map((type) => (
        <Grid item key={type.label}>
          <StyleButton
            active={currentStyle.has(type.style)}
            onToggle={onToggle}
            style={type.style}
            label={type.label}
            children={type.children}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default InlineStyleControls;
