import React, { useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

const DynamicHTMLDisplay = ({ rawData }) => {
  const classes = useStyles()();

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.innerHTML = generateBlocks(
      rawData?.blocks,
      rawData?.entityMap,
    );
  }, [rawData?.blocks, rawData?.entityMap]);

  return <div ref={contentRef} className={classes['content-----container']} />;
};

export default DynamicHTMLDisplay;

const useStyles = () => {
  const theme = useTheme();
  const classes = contentStyles(theme);

  return makeStyles(() => ({
    'content-----container': {
      '& h1': classes.ContentHeader1,
      '& h2': classes.ContentHeader2,
      '& h3': classes.ContentHeader3,
      '& h4': classes.ContentHeader4,
      '& h5': classes.ContentHeader5,
      '& h6': classes.ContentHeader6,

      '& ul': classes.UnorderedList,
      '& ol': classes.OrderedList,
    },
  }));
};

export const contentStyles = (theme) => ({
  ContentHeader1: {},
  ContentHeader2: {},
  ContentHeader3: {},
  ContentHeader4: {},
  ContentHeader5: {},
  ContentHeader6: {},

  UnorderedList: {
    margin: '16px 0',
    padding: 0,
    '& > li': {
      position: 'relative',
      direction: 'ltr',
      marginLeft: '1.5em',
      listStyleType: 'disc',
    },
  },
  OrderedList: {
    margin: '16px 0',
    padding: 0,
    '& > li': {
      position: 'relative',
      direction: 'ltr',
      marginLeft: '1.5em',
      listStyleType: 'none',

      '&::before': {
        left: -36,
        position: 'absolute',
        textAlign: 'right',
        width: 30,
        content: 'counter(ol0) ". "',
        counterIncrement: 'ol0',
      },
    },
  },
});

export function generateBlocks(blocks, entityMap) {
  if (!blocks) {
    return '';
  }

  return blocks
    .map((item) => ({
      ...item,
      text: generateInlineString(
        item.inlineStyleRanges,
        item.entityRanges,
        item.text,
        entityMap,
      ),
    }))
    .map((block, index) => {
      switch (block.type) {
        case 'unordered-list-item':
        case 'ordered-list-item':
          return generateOutputListBlock(
            block.type,
            block,
            blocks[index - 1],
            blocks[index + 1],
          );

        case 'header-one':
        case 'header-two':
        case 'header-three':
        case 'header-four':
        case 'header-five':
        case 'header-six':
          const tag =
            block.type === 'header-one'
              ? 'h1'
              : block.type === 'header-two'
              ? 'h2'
              : block.type === 'header-three'
              ? 'h3'
              : block.type === 'header-four'
              ? 'h4'
              : block.type === 'header-five'
              ? 'h5'
              : 'h6';
          return `<${tag}>${block.text}</${tag}>`;

        default:
          return `<div>${block.text.trim() || '&nbsp;'}</div>`;
      }
    })
    .join('');
}

export function generateInlineString(
  inlineStyleRanges,
  entityRanges,
  text,
  entityMap,
) {
  let prevToken = [];

  return text
    .split('')
    .map((letter, index) => {
      let curToken = [];
      let generatedInlineStyles = '';

      for (const range of inlineStyleRanges) {
        if (index >= range.offset && index < range.offset + range.length) {
          curToken.push(range.style);
        }
      }

      if (
        entityMap &&
        typeof entityMap === 'object' &&
        Array.isArray(entityRanges) &&
        entityRanges.length > 0
      ) {
        for (const range of entityRanges) {
          if (index >= range.offset && index < range.offset + range.length) {
            curToken.push([
              entityMap[range.key + ''].type,
              entityMap[range.key + ''].data.url,
            ]);
          }
        }
      }

      if (prevToken.join('') !== curToken.join('')) {
        const normalPrevStylings = prevToken.filter(
          (tok) => typeof tok === 'string',
        );
        const normalCurStylings = curToken.filter(
          (tok) => typeof tok === 'string',
        );

        if (curToken.length > 0) {
          for (const token of curToken) {
            if (Array.isArray(token) && token[0] === 'LINK') {
              letter = `<a style="text-decoration: underline; color: #1021b4;" target="_blank" rel="noreferrer" href="${
                token[1].startsWith('http://') ||
                token[1].startsWith('https://') ||
                token[1].startsWith('ftp://')
                  ? token[1]
                  : 'https://' + token[1]
              }">${letter}`;
            } else if (
              typeof token === 'string' &&
              normalPrevStylings.join('') !== normalCurStylings.join('')
            ) {
              generatedInlineStyles +=
                token === 'BOLD'
                  ? 'font-weight: 700;'
                  : token === 'ITALIC'
                  ? 'font-style: italic;'
                  : token === 'UNDERLINE'
                  ? 'text-decoration: underline;'
                  : '';
            }
          }

          if (generatedInlineStyles) {
            letter = `<span style="${generatedInlineStyles}">${letter}`;
          }
        }

        if (prevToken.join('')) {
          const prevTokenLink = prevToken.find(
            (tok) => Array.isArray(tok) && tok[0] === 'LINK',
          );
          const curTokenLink = curToken.find(
            (tok) => Array.isArray(tok) && tok[0] === 'LINK',
          );

          if (
            (prevTokenLink && !curTokenLink) ||
            (curTokenLink &&
              prevTokenLink &&
              prevTokenLink[1] !== curTokenLink[1])
          ) {
            letter = '</a>' + letter;
          }

          if (normalPrevStylings.join('') !== normalCurStylings.join('')) {
            letter = '</span>' + letter;
          }
        }
      }
      if (curToken.length > 0 && index + 1 === text.length) {
        if (
          curToken.findIndex(
            (tok) =>
              Array.isArray(tok) && tok[0] === 'LINK',
          ) !== -1
        ) {
          letter += '</a>';
        }

        if (curToken.findIndex((tok) => typeof tok === 'string') !== 1) {
          letter += '</span>';
        }
      }

      prevToken = [...curToken];

      return letter;
    })
    .join('');
}

export function generateOutputListBlock(type, block, prevBlock, nextBlock) {
  let outputStr = '';

  if (type !== 'ordered-list-item' && type !== 'unordered-list-item') {
    return '';
  }

  if (!prevBlock || prevBlock.type !== type) {
    outputStr = `${
      type === 'ordered-list-item' ? '<ol>' : '<ul>'
    }<li style="counter-reset: ol0;">${block.text}</li>`;
  } else {
    outputStr = `<li>${block.text}</li>`;
  }

  if (!nextBlock || nextBlock.type !== type) {
    outputStr += type === 'ordered-list-item' ? '</ol>' : '</ul>';
  }

  return outputStr;
}
